/* eslint-disable @typescript-eslint/no-explicit-any */
import {Tag} from '@intraversa-lab/styleguide'
import {Column} from 'types/SchedulerConfig'

export const renderCellColumns: Record<
  Column['renderCell'],
  (value: any, row: any) => any
> = {
  status: (value, row) =>
    row?.email ? (
      <>
        <Tag
          label={value ? 'Sucesso' : 'Error'}
          variant={value ? 'success' : 'danger'}
        />
      </>
    ) : (
      <>-</>
    ),
}
