import {api} from 'configs'
import {endpointsScheduled} from 'infra/endpoints'

async function getRpaConfigByRpaId(rpaId: string) {
  return await api.get(
    `${endpointsScheduled.getRpaConfigByRpaId.replace(':rpaId', rpaId)}`,
  )
}

export const rpaConfigApi = {
  getRpaConfigByRpaId,
}
