/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import {TextField, ButtonPortico, Modal, If} from '@intraversa-lab/styleguide'
import {
  EMAIL_TYPE,
  ENUM_EMAIL_TYPE,
  ISchedulerConfigBase,
} from 'components/ContainerCollapse/meta/schedulerConfigTypes'
import {IResponseEmailConfig, IResponseEmailDetail} from 'domains'
import {IRpaConfigResponse} from 'domains/RpaConfig/rpaConfigTypes'

import * as SContainerCollapse from '../../styles'

import * as S from './styles'

interface IModalEditCredentialsProps {
  isOpen: boolean
  onClose: () => void
  data?: IRpaConfigResponse['config']['outbounds'][0]['credential']
  dataEmail?: IResponseEmailConfig & IResponseEmailDetail
  configSelected: ISchedulerConfigBase
  typeEmail?: ENUM_EMAIL_TYPE
}

export const ModalEditCredentials: React.FC<IModalEditCredentialsProps> = ({
  configSelected,
  data,
  isOpen,
  onClose,
  dataEmail,
  typeEmail,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px'}>
        <S.Container>
          <div className="Container--Header">
            <S.Title>
              {typeEmail === 'AUTOMATIC'
                ? configSelected?.titleModal2
                : configSelected?.titleModal}
            </S.Title>
            <S.Description>
              {typeEmail === 'AUTOMATIC'
                ? configSelected?.descriptionModal2
                : configSelected?.descriptionModal}
            </S.Description>
          </div>

          <S.WrapperInputs>
            <If condition={configSelected?.type !== 'EMAIL'}>
              {configSelected?.fields.map((item) => (
                <SContainerCollapse.WrapperInput key={item.name}>
                  <SContainerCollapse.TextLg className="labelInput">
                    {item.label}
                  </SContainerCollapse.TextLg>
                  <TextField
                    showIconPassword={false}
                    type={item.typeInput}
                    defaultValue={(data?.data as any)?.[item.name]}
                    disabled
                  />
                </SContainerCollapse.WrapperInput>
              ))}
            </If>

            <If
              condition={
                configSelected?.type === 'EMAIL' && typeEmail === 'CONNECTION'
              }>
              {configSelected?.fields
                ?.filter((item) => item.typeEmail === EMAIL_TYPE.CONNECTION)
                .map((item) => (
                  <SContainerCollapse.WrapperInput key={item.name}>
                    <SContainerCollapse.TextLg className="labelInput">
                      {item.label}
                    </SContainerCollapse.TextLg>
                    <TextField
                      showIconPassword={false}
                      type={item.typeInput}
                      value={(dataEmail as any)?.[item.name]}
                      disabled
                    />
                  </SContainerCollapse.WrapperInput>
                ))}
            </If>

            <If
              condition={
                configSelected?.type === 'EMAIL' && typeEmail === 'AUTOMATIC'
              }>
              {configSelected?.fields
                ?.filter((item) => item.typeEmail === EMAIL_TYPE.AUTOMATIC)
                .map((item) => (
                  <SContainerCollapse.WrapperInput key={item.name}>
                    <SContainerCollapse.TextLg className="labelInput">
                      {item.label}
                    </SContainerCollapse.TextLg>
                    <TextField
                      type={item.typeInput}
                      showIconPassword={false}
                      value={dataEmail?.[item.name]}
                      disabled
                      {...(!!item?.multiline && {
                        multiline: item?.multiline as any,
                      })}
                    />
                  </SContainerCollapse.WrapperInput>
                ))}
            </If>
          </S.WrapperInputs>

          <S.WrapperButtons>
            <ButtonPortico variant="outlined" onClick={onClose}>
              Cancelar
            </ButtonPortico>
            <ButtonPortico disabled>Salvar alterações</ButtonPortico>
          </S.WrapperButtons>
        </S.Container>
      </Modal>
    </>
  )
}
