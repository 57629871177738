import {styled} from 'styled-components'

export const Wrapper = styled.div`
  background: #f9f9f9;
  min-height: 100vh;
`

export const WrapperTabs = styled.div`
  margin-top: 16px;
  box-sizing: border-box;

  padding: 0px 32px;
`

export const Content = styled.div`
  margin: 32px 0px;
  box-sizing: border-box;

  padding: 0px 32px;
`
