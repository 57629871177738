import {api} from 'configs'
import {endpointsEmail} from 'infra'

import {IPayloadEmailTest} from './emailTypes'

const getEmailDetails = async ({emailId}: {emailId: string}) => {
  return await api.get(
    `${endpointsEmail.getEmailDetails.replace(':emailId', emailId)}`,
  )
}

const getEmailConfigs = async ({emailId}: {emailId: string}) => {
  return await api.get(
    `${endpointsEmail.getEmailConfig.replace(':emailId', emailId)}`,
  )
}

const getEmailAttachments = async ({emailId}: {emailId: string}) => {
  return await api.get(
    `${endpointsEmail.getEmailAttachements.replace(':emailId', emailId)}`,
  )
}

const postEmailTest = async (params: IPayloadEmailTest) => {
  return await api.post(`${endpointsEmail.postEmailTest}`, {params})
}

export const emailApi = {
  getEmailDetails,
  getEmailConfigs,
  getEmailAttachments,
  postEmailTest,
}
