export const IconRefetch = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2260_6080"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2260_6080)">
        <path
          d="M9.98455 17.0831C8.18007 17.0831 6.60742 16.4891 5.26662 15.3011C3.92581 14.113 3.15391 12.6237 2.95093 10.8332H4.22653C4.437 12.2722 5.0847 13.4654 6.16964 14.4125C7.25458 15.3596 8.52622 15.8332 9.98455 15.8332C11.6096 15.8332 12.988 15.2672 14.12 14.1352C15.2519 13.0033 15.8179 11.6248 15.8179 9.99982C15.8179 8.37482 15.2519 6.99635 14.12 5.8644C12.988 4.73246 11.6096 4.16648 9.98455 4.16648C9.0743 4.16648 8.22121 4.36867 7.42526 4.77305C6.62933 5.17742 5.94397 5.73377 5.36918 6.44211H7.54866V7.69209H3.31795V3.46136H4.56791V5.43573C5.24418 4.63766 6.05401 4.01828 6.99739 3.57757C7.94076 3.13686 8.93648 2.9165 9.98455 2.9165C10.9675 2.9165 11.8884 3.10241 12.7474 3.47421C13.6063 3.846 14.3552 4.35134 14.9941 4.99021C15.633 5.6291 16.1384 6.37803 16.5102 7.237C16.8819 8.09598 17.0678 9.01691 17.0678 9.99982C17.0678 10.9827 16.8819 11.9037 16.5102 12.7626C16.1384 13.6216 15.633 14.3705 14.9941 15.0094C14.3552 15.6483 13.6063 16.1536 12.7474 16.5254C11.8884 16.8972 10.9675 17.0831 9.98455 17.0831ZM12.4862 13.3556L9.38359 10.253V5.83315H10.6336V9.74661L13.3643 12.4774L12.4862 13.3556Z"
          fill="#75808A"
        />
      </g>
    </svg>
  )
}
