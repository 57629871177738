import {LocalStorage} from 'infra'
import {UserApi} from 'types/userTypes'

import {getUser} from './getUser'

interface IRoles {
  active: boolean
  createdAt: string
  description: string
  name: string
  id: string
}

export const useIsAdmin = () => {
  const getRole = () => {
    const user: UserApi | undefined = getUser()
    const roles = localStorage.getItem(LocalStorage.ROLES)
    const rolesFormat: IRoles[] = JSON.parse(roles) || []

    if (user && !!roles?.length) {
      const isAdmin = rolesFormat.some((item) => {
        const userHaveRole = user.roles.some(
          (roleUser) => roleUser.roleId === item.id,
        )
        const roleIsAdmin = String(item.name)
          .toLocaleUpperCase()
          .includes('ADMIN')
        return userHaveRole && roleIsAdmin
      })

      return isAdmin
    }

    return false
  }

  return getRole()
}
