import {api} from 'configs'
import {endpointsRpaRun, endpointsSteps} from 'infra/endpoints'

import {IParamsGetHistory, IParamsStepDetails} from './rpaRunTypes'

async function getHistory(params: IParamsGetHistory) {
  return await api.get(`${endpointsRpaRun.getHistory}`, {params})
}

async function getNextExecution() {
  return await api.get(`${endpointsRpaRun.getNextExecution}`)
}

async function getSteps() {
  return await api.get(`${endpointsSteps.getSteps}`)
}

async function postRunNow() {
  return await api.post(`${endpointsRpaRun.postRpaRunNow}`)
}

async function getStepDetails({rpaId, step}: IParamsStepDetails) {
  return await api.get(`${endpointsRpaRun.getSteps.replace(':runId', rpaId)}`, {
    params: {step},
  })
}

export const rpaRunApi = {
  getHistory,
  getNextExecution,
  getSteps,
  getStepDetails,
  postRunNow,
}
