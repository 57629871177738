import React, {useEffect, useMemo} from 'react'

import {
  If,
  LogoPortico,
  Modal,
  useConfirmModal,
} from '@intraversa-lab/styleguide'

import {Countdown} from '../Countdown'

import * as S from './styles'

export type IModalConnectivityType = 'PENDING' | 'SUCCESS' | 'ERROR'
interface IModalConnectivity {
  isOpen: boolean
  onClose: () => void
  status: 'PENDING' | 'SUCCESS' | 'ERROR'
}

const IconSuccess = () => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="48" height="48" rx="24" fill="#38FF97" />
      <mask
        id="mask0_2461_22622"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="33"
        height="32">
        <rect x="8.5" y="8" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2461_22622)">
        <path
          d="M22.5346 30.068L31.968 20.6346L30.4346 19.1346L22.5346 27.0346L18.5346 23.0346L17.0346 24.5346L22.5346 30.068ZM24.5013 37.3346C22.6791 37.3346 20.9569 36.9846 19.3346 36.2846C17.7124 35.5846 16.2957 34.6291 15.0846 33.418C13.8735 32.2069 12.918 30.7902 12.218 29.168C11.518 27.5457 11.168 25.8235 11.168 24.0013C11.168 22.1569 11.518 20.4235 12.218 18.8013C12.918 17.1791 13.8735 15.768 15.0846 14.568C16.2957 13.368 17.7124 12.418 19.3346 11.718C20.9569 11.018 22.6791 10.668 24.5013 10.668C26.3457 10.668 28.0791 11.018 29.7013 11.718C31.3235 12.418 32.7346 13.368 33.9346 14.568C35.1346 15.768 36.0846 17.1791 36.7846 18.8013C37.4846 20.4235 37.8346 22.1569 37.8346 24.0013C37.8346 25.8235 37.4846 27.5457 36.7846 29.168C36.0846 30.7902 35.1346 32.2069 33.9346 33.418C32.7346 34.6291 31.3235 35.5846 29.7013 36.2846C28.0791 36.9846 26.3457 37.3346 24.5013 37.3346ZM24.5013 35.3346C27.6569 35.3346 30.3346 34.2291 32.5346 32.018C34.7346 29.8069 35.8346 27.1346 35.8346 24.0013C35.8346 20.8457 34.7346 18.168 32.5346 15.968C30.3346 13.768 27.6569 12.668 24.5013 12.668C21.368 12.668 18.6957 13.768 16.4846 15.968C14.2735 18.168 13.168 20.8457 13.168 24.0013C13.168 27.1346 14.2735 29.8069 16.4846 32.018C18.6957 34.2291 21.368 35.3346 24.5013 35.3346Z"
          fill="#F9F9F9"
        />
      </g>
    </svg>
  )
}

export const ModalConnectivity: React.FC<IModalConnectivity> = ({
  isOpen,
  onClose,
  status = 'PENDING',
}) => {
  const {renderModal, updateModalStateAndOpenModal} = useConfirmModal()
  const [hasFinishCountdown, setHasFinishCountdown] = React.useState(false)

  const isError = status === 'ERROR'

  const renderTestingConnectivity = () => {
    return (
      <>
        <LogoPortico />
        <S.Title>Teste de Conectividade</S.Title>
        <S.Description>
          Estamos verificando se suas credenciais estão em sincronia, isso pode
          levar alguns segundos.
        </S.Description>
        <S.LabelCount>
          Tempo restante:{' '}
          <Countdown
            start={10}
            setCountComplete={(value) => setHasFinishCountdown(value)}
          />{' '}
          segundos
        </S.LabelCount>
      </>
    )
  }

  const renderSuccess = () => {
    return (
      <>
        <IconSuccess />
        <S.Title>Teste realizado com sucesso!</S.Title>
        <S.Description>Suas plataformas estão sincronizadas.</S.Description>
      </>
    )
  }

  const variant = useMemo(() => {
    if (status === 'SUCCESS' && hasFinishCountdown) {
      return 'success'
    }

    if (status === 'ERROR' && hasFinishCountdown) {
      return 'danger'
    }

    return 'default'
  }, [status, hasFinishCountdown])

  const renderModalContent = () => {
    if (status === 'SUCCESS' && hasFinishCountdown) {
      return renderSuccess()
    }

    if (status === 'PENDING' || !hasFinishCountdown) {
      return renderTestingConnectivity()
    }
  }

  useEffect(() => {
    if (isError) {
      updateModalStateAndOpenModal({
        title: `Erro de Conectividade`,
        description: `Ops! Parece que algo deu errado. Você pode tentar novamente ou entre em contato com o suporte.`,
        onConfirm: () => {},
        type: 'error',
        maxWidth: 417,
      })
    }
  }, [isError])

  return (
    <>
      <If condition={!isError}>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          maxWidth={449}
          showCloseButton={false}
          defaultPaddingContent={'36px 32px'}
          variant={variant}>
          <S.Container>{renderModalContent()}</S.Container>
        </Modal>
      </If>

      <If condition={isError}>{renderModal()}</If>
    </>
  )
}
