/* eslint-disable @typescript-eslint/no-explicit-any */
import {QueryKeys} from 'infra'
import {UseQueryOptions, useQuery} from 'react-query'

import {rpaRunService} from '../rpaRunService'
import {IParamsStepDetails, IResponseStepDetails} from '../rpaRunTypes'

export function useGetStepDetails(
  params: IParamsStepDetails,
  options?: UseQueryOptions<any, any, any, any>,
) {
  return useQuery<IResponseStepDetails[]>({
    queryKey: [QueryKeys.GetStepDetails, params],
    queryFn: async () => {
      return rpaRunService.getStepDetails(params)
    },
    enabled: Boolean(params?.step) && Boolean(params?.rpaId),
    staleTime: 1000 * 30,
    ...options,
  })
}
