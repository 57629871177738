export const IconArrowRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2962_11933"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2962_11933)">
        <path
          d="M13.2508 11.975L8.30078 7.02495L9.37578 5.94995L15.4008 11.975L9.37578 18L8.30078 16.925L13.2508 11.975Z"
          fill="#161F28"
        />
        <path
          d="M13.2508 11.975L8.30078 7.02495L9.37578 5.94995L15.4008 11.975L9.37578 18L8.30078 16.925L13.2508 11.975Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M13.2508 11.975L8.30078 7.02495L9.37578 5.94995L15.4008 11.975L9.37578 18L8.30078 16.925L13.2508 11.975Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M13.2508 11.975L8.30078 7.02495L9.37578 5.94995L15.4008 11.975L9.37578 18L8.30078 16.925L13.2508 11.975Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M13.2508 11.975L8.30078 7.02495L9.37578 5.94995L15.4008 11.975L9.37578 18L8.30078 16.925L13.2508 11.975Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
    </svg>
  )
}
