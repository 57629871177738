/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback, useMemo, useState} from 'react'

import {
  TextField,
  ButtonPortico,
  If,
  useConfirmModal,
} from '@intraversa-lab/styleguide'
import {IconEdit, IconReload} from 'components/Icons/Icons'
import {
  IResponseEmailConfig,
  IResponseEmailDetail,
  useGetEmailAttachments,
  useGetEmailConfig,
  useGetEmailDetails,
  usePostTestConnection,
} from 'domains'
import {IRpaConfigResponse} from 'domains/RpaConfig/rpaConfigTypes'
import moment from 'moment'

import {
  IModalConnectivityType,
  ModalConnectivity,
} from './components/ModalConnectivity'
import {ModalEditConnectivityEmail} from './components/ModalConnectivityEmail'
import {ModalEditCredentials} from './components/ModalEditCredentials'
import {
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes,
  EMAIL_TYPE,
  ENUM_EMAIL_TYPE,
} from './meta/schedulerConfigTypes'
import * as S from './styles'

export interface CardIntegrationProps {
  type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES
  data: IRpaConfigResponse['config']['outbounds'][0]['credential']
  configId: string
  title?: string
}

export const CardIntegration: React.FC<CardIntegrationProps> = ({
  type,
  data,
  title,
}) => {
  const isEmail = type === 'EMAIL'
  const [showLabelButton, setShowLabelButton] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalEmail, setShowModalEmail] = useState(false)
  const [typeEmailSelected, setTypeEmailSelected] =
    useState<ENUM_EMAIL_TYPE>('CONNECTION')
  const [showTestingConnectivity, setShowTestingConnectivity] = useState(false)

  // SERVICES
  const {
    onTestConnection,
    isLoading: isLoadingTestConnection,
    isSuccess: isSuccessConnection,
    isError,
  } = usePostTestConnection()
  const {data: emailConfig, isSuccess} = useGetEmailConfig({
    emailId: (data.data as any)?.id,
    enabled: isEmail,
  })
  const {data: emailDetails, isSuccess: isSuccessDetails} = useGetEmailDetails({
    emailId: (data.data as any)?.id,
    enabled: isEmail && isSuccess,
  })
  const {data: emailAttachments} = useGetEmailAttachments({
    emailId: (data.data as any)?.id,
    enabled: isEmail && isSuccessDetails,
  })

  const configSelected = schedulerConfigTypes.find(
    (config) => config.type === type,
  )

  const dataEmail: IResponseEmailConfig & IResponseEmailDetail = useMemo(() => {
    if (emailDetails && emailConfig) {
      return {
        ...emailDetails,
        ...emailConfig,
      } as any
    }

    return {} as any
  }, [emailDetails, emailConfig])

  const nameFileAnexo = useMemo(() => {
    if (emailAttachments?.length) {
      const {extension, nameArchive} = emailAttachments[0]
      return `${nameArchive}.${extension}`
    }

    return ''
  }, [emailAttachments])

  const typeConnection: IModalConnectivityType = useMemo(() => {
    if (isError) {
      return 'ERROR'
    }
    if (isSuccessConnection) {
      return 'SUCCESS'
    }
    return 'PENDING'
  }, [isSuccessConnection, isLoadingTestConnection, isError])

  const handleOpenAnexo = useCallback(() => {
    if (emailAttachments?.length) {
      const {base64, extension} = emailAttachments[0]

      const binaryStr = window.atob(base64)

      let length = binaryStr.length
      const uintArray = new Uint8Array(length)
      while (length--) {
        uintArray[length] = binaryStr.charCodeAt(length)
      }
      const blob = new Blob([uintArray], {type: `application/${extension}`})

      const blobUrl = URL.createObjectURL(blob)
      window.open(blobUrl, '_blank')
    }
  }, [emailAttachments])

  const onOpenModal = (type?: ENUM_EMAIL_TYPE) => {
    setShowModal(true)

    if (isEmail) {
      setTypeEmailSelected(type)
    }
  }

  const handleTestConnection = () => {
    const isEmail = configSelected?.type === 'EMAIL'

    if (isEmail) {
      setShowModalEmail(true)
    } else {
      setShowTestingConnectivity(true)
      onTestConnection({
        typeConfig: configSelected.apiConnection,
      })
    }
  }

  return (
    <S.CollapseContainer>
      <S.Wrapper>
        <S.CollapseHeader>
          <div>
            <S.TextLg>
              {title || (data as any)?.description || configSelected?.title}
            </S.TextLg>
            <S.TextDescription>
              Ultima edição {moment().format('DD/MM/YYYY HH:mm:ss')}
            </S.TextDescription>
          </div>

          <S.WrapperButtons>
            <ButtonPortico
              style={{height: '40px !important'}}
              variant="outlined"
              onClick={handleTestConnection}
              startIcon={<IconReload />}
              onMouseEnter={() => setShowLabelButton(true)}
              onMouseLeave={() => setShowLabelButton(false)}>
              {showLabelButton && 'Testar Conectividade'}
            </ButtonPortico>

            <div className="middle-button">
              <ButtonPortico
                startIcon={<IconEdit />}
                variant="outlined"
                onClick={() => onOpenModal('CONNECTION')}>
                Editar credenciais
              </ButtonPortico>
            </div>
          </S.WrapperButtons>
        </S.CollapseHeader>

        <S.CollapseContent>
          <If condition={!isEmail}>
            {configSelected?.fields.map((item) => (
              <S.WrapperInput key={item.name}>
                <S.TextLg className="labelInput">{item.label}</S.TextLg>
                <TextField
                  type={item.typeInput}
                  showIconPassword={false}
                  defaultValue={(data?.data as any)?.[item.name]}
                  disabled
                />
              </S.WrapperInput>
            ))}
          </If>

          <If condition={isEmail && !!dataEmail}>
            {configSelected?.fields
              ?.filter((item) => item.typeEmail === EMAIL_TYPE.CONNECTION)
              .map((item) => (
                <S.WrapperInput key={item.name}>
                  <S.TextLg className="labelInput">{item.label}</S.TextLg>
                  <TextField
                    type={item.typeInput}
                    showIconPassword={false}
                    value={(dataEmail as any)?.[item.name]}
                    disabled
                  />
                </S.WrapperInput>
              ))}
          </If>
        </S.CollapseContent>
      </S.Wrapper>

      <If condition={isEmail}>
        <S.Wrapper style={{marginTop: 32}}>
          <S.CollapseHeader>
            <div>
              <S.TextLg>{configSelected?.titleModal2}</S.TextLg>
              <S.TextDescription>
                Ultima edição 20/11/2023 09:34:01
              </S.TextDescription>
            </div>

            <S.WrapperButtons>
              <div className="middle-button">
                <ButtonPortico
                  startIcon={<IconEdit />}
                  variant="outlined"
                  style={{height: 40}}
                  onClick={() => onOpenModal('AUTOMATIC')}>
                  Editar emails automáticos
                </ButtonPortico>
              </div>
            </S.WrapperButtons>
          </S.CollapseHeader>

          <S.CollapseContent>
            {configSelected?.fields
              ?.filter((item) => item.typeEmail === EMAIL_TYPE.AUTOMATIC)
              .map((item) => (
                <S.WrapperInput key={item.name}>
                  <S.TextLg className="labelInput">{item.label}</S.TextLg>
                  <TextField
                    type={item.typeInput}
                    value={(dataEmail as any)?.[item.name]}
                    showIconPassword={false}
                    disabled
                    {...(!!item?.multiline && {
                      multiline: item?.multiline as any,
                    })}
                  />
                </S.WrapperInput>
              ))}

            <S.WrapperInput>
              <S.TextLg className="labelInput">Anexo</S.TextLg>

              {nameFileAnexo ? (
                <ButtonPortico variant="outlined" onClick={handleOpenAnexo}>
                  {nameFileAnexo}
                </ButtonPortico>
              ) : (
                <S.TextFile>Nenhum anexo selecionado</S.TextFile>
              )}
            </S.WrapperInput>
          </S.CollapseContent>
        </S.Wrapper>
      </If>

      <ModalEditCredentials
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        configSelected={configSelected as any}
        data={data}
        dataEmail={dataEmail}
        typeEmail={typeEmailSelected}
      />

      <ModalEditConnectivityEmail
        isOpen={showModalEmail}
        onClose={() => setShowModalEmail(false)}
      />

      <ModalConnectivity
        isOpen={showTestingConnectivity}
        onClose={() => setShowTestingConnectivity(false)}
        status={typeConnection as any}
      />
    </S.CollapseContainer>
  )
}
