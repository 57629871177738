import { pipeline, z } from "zod";

// const roleSchema = z.object({
//   label: z.string(),
//   value: z.string()
// })

export const businessSchema = z
  .object({
    stage: z.string().min(1, "Campo Obrigatório"),
    documentCustomField: z.string().min(1, "Campo Obrigatório"),
    endereco: z.string().min(1, "Campo Obrigatório"),
    estado: z.string().min(1, "Campo Obrigatório"),
    cidade: z.string().min(1, "Campo Obrigatório"),
    bairro: z.string().min(1, "Campo Obrigatório"),
    cep: z.string().min(1, "Campo Obrigatório"),
    complemento: z.string().min(1, "Campo Obrigatório"),
    numero: z.string().min(1, "Campo Obrigatório"),
    currentAccount: z.string().min(1, "Campo Obrigatório"),
    billingDays: z.string().min(1, "Campo Obrigatório"),
    effectiveDays: z.string().min(1, "Campo Obrigatório"),
  })
  .refine((data) => data.stage !== undefined, {
    message: "Campo Obrigatório",
  });

export type BusinessSchema = z.infer<typeof businessSchema>;
