import {emailApi} from './emailApi'
import {
  IPayloadEmailTest,
  IResponseEmailAttachements,
  IResponseEmailConfig,
  IResponseEmailDetail,
} from './emailTypes'

const getEmailDetails = async ({
  emailId,
}: {
  emailId: string
}): Promise<IResponseEmailDetail> => {
  try {
    const {data} = await emailApi.getEmailDetails({emailId})
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const getEmailConfigs = async ({
  emailId,
}: {
  emailId: string
}): Promise<IResponseEmailConfig> => {
  try {
    const {data} = await emailApi.getEmailConfigs({emailId})
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const getEmailAttachments = async ({
  emailId,
}: {
  emailId: string
}): Promise<IResponseEmailAttachements> => {
  try {
    const {data} = await emailApi.getEmailAttachments({emailId})
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const postEmailTest = async (
  params: IPayloadEmailTest,
): Promise<IResponseEmailAttachements[]> => {
  try {
    const {data} = await emailApi.postEmailTest(params)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export const emailService = {
  getEmailDetails,
  getEmailConfigs,
  getEmailAttachments,
  postEmailTest,
}
