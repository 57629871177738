import {rpaRunApi} from './rpaRunApi'
import {
  IParamsGetHistory,
  IParamsStepDetails,
  IResponseHistory,
  IResponseNextExecution,
  IResponseSteps,
} from './rpaRunTypes'

async function getHistory(
  params: IParamsGetHistory,
): Promise<IResponseHistory> {
  try {
    const {data} = await rpaRunApi.getHistory(params)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

async function getNextExecution(): Promise<IResponseNextExecution> {
  try {
    const {data} = await rpaRunApi.getNextExecution()
    return data
  } catch (e) {
    throw new Error(e)
  }
}

async function getSteps(): Promise<IResponseSteps[]> {
  try {
    const {data} = await rpaRunApi.getSteps()
    return data
  } catch (e) {
    throw new Error(e)
  }
}

async function getStepDetails(
  params: IParamsStepDetails,
): Promise<IResponseSteps[]> {
  try {
    const {data} = await rpaRunApi.getStepDetails(params)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

async function postRunNow(): Promise<IResponseSteps[]> {
  try {
    const {data} = await rpaRunApi.postRunNow()
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export const rpaRunService = {
  getHistory,
  getNextExecution,
  getSteps,
  getStepDetails,
  postRunNow,
}
