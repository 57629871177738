import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'
import {SchedulerConfig} from 'types/SchedulerConfig'

import {rpaService} from '../rpaService'

export function useGetVisualConfig() {
  const {data, isLoading, isError, refetch, isFetching} =
    useQuery<SchedulerConfig>({
      queryKey: [QueryKeys.GetConfigRpa],
      queryFn: async () => await rpaService.getVisualConfig(),
      staleTime: 1000 * 30,
    })

  return {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}
