import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {rpaRunService} from '../rpaRunService'
import {IResponseSteps} from '../rpaRunTypes'

export function useGetSteps() {
  return useQuery<IResponseSteps[]>({
    queryKey: [QueryKeys.GetSteps],
    queryFn: async () => {
      return rpaRunService.getSteps()
    },
    staleTime: 1000 * 30,
  })
}
