import {styled} from 'styled-components'

export const Wrapper = styled.div``

export const WrapperDescription = styled.div`
  border-bottom: 1px solid #eff2f4;
  padding-bottom: 32px;

  .description-content {
    display: flex;
    margin-top: 16px;

    .description-content__item + .description-content__item {
      margin-left: 32px;
    }
  }

  .description-content__item {
    .item__title {
      display: flex;
      align-items: center;

      .tile_text {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #59636b;
        margin-left: 8px;
      }
    }
  }
`

export const WrapperExecutions = styled.div`
  .wrapper-table {
    margin-top: 32px;
  }
`

export const WrapperStep = styled.div`
  display: flex;
  align-items: center;

  margin-left: 16px;
  margin-bottom: 16px;

  .WrapperStep--Title {
    padding: 0px;
    margin: 0px;
    margin-right: 16px;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
  }

  .WrapperStep--Select {
    width: 412px;
  }
`
