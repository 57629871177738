/* eslint-disable @typescript-eslint/no-explicit-any */
import {AxiosInstance} from 'axios'
import {UseMutationOptions, UseQueryOptions, UseQueryResult} from 'react-query'

export const BASE_URL_PORTICO = 'https://portico-api.intraversa.com/api/v1'

export enum QueryKeys {
  GetConfigRpa = 'GetConfigRpa',
  GetHistory = 'GetHistory',
  GetNextExecution = 'GetNextExecution',
  GetSteps = 'GetSteps',
  GetStepDetails = 'GetStepDetails',
  GetEmailConfig = 'GetEmailConfig',
  GetEmailAttachments = 'GetEmailAttachments',
  GetEmailDetails = 'GetEmailDetails',
  GetVisualConfig = 'GetVisualConfig',
}

export interface IQueryOptions extends UseQueryOptions<any, any, any, any> {}
export interface IMutationOptions
  extends UseMutationOptions<any, any, any, any> {}

export interface IFormatResponse<TResponse, TFormatter> {
  formatResponse?: (data: TResponse) => TFormatter
  axios?: AxiosInstance
}

export type TUseQueryTyped<TParams, TResponse> = <TFormatter = TResponse>(
  params: TParams,
  options?: IQueryOptions & IFormatResponse<TResponse, TFormatter>,
) => UseQueryResult<TFormatter, any>

export interface MutationOptions<TData> {
  onSuccess?: (data: TData) => void
  onError?: (message: string) => void
  errorMessage?: string
}

export enum LocalStorage {
  ACCESS_TOKEN = '@PORTICO:ACCESS_TOKEN',
  USER_LOGGED = '@PORTICO:USER_LOGGED',
  STATUS = '@PORTICO:STATUS',
  ENV = '@PORTICO:ENV',
  ROLES = '@PORTICO:ROLES',
}
