import React, {useMemo} from 'react'

import {If} from '@intraversa-lab/styleguide'
import {Box, CircularProgress} from '@mui/material'
import Tab from '@mui/material/Tab'
import TabsMUI from '@mui/material/Tabs'
import {HeaderContent} from 'components/HeaderContent/HeaderContent'
import {
  IResponseHistory,
  useGetHistory,
  useGetNextExecution,
  useGetSteps,
} from 'domains'
import {useGetVisualConfig} from 'domains/Rpa'
import {useGetConfigRpa} from 'domains/RpaConfig/useCases'
import {useIsAdmin} from 'hooks/useIsAdmin'
import moment from 'moment'
import {useParams, useSearchParams} from 'react-router-dom'
import { BusinessContainer } from './containers/Business/business-container'
import {SchedulerConfig} from 'types/SchedulerConfig'
import {ConfigContainer} from './containers/Configs/config-container'
import {IntegrationsContainter} from './containers/Integrations/Integrations'
import * as S from './styles'

const ScheduledPage = () => {
  const [searchParams] = useSearchParams()

  const [value, setValue] = React.useState(() => {
    const code = searchParams.get('code')
    return code ? 'configs' : 'integration'
  })

  const [historyParams, setHistoryParams] = React.useState({
    page: 0,
    offset: 10,
    total: 10,
  })

  const isAdmin = useIsAdmin()
  
  const {rpaId} = useParams()

  const code = searchParams.get('code')
  const state = searchParams.get('state')
  const domain = searchParams.get('domain')
  const memberId = searchParams.get('member_id')
  const scope = searchParams.get('scope')
  const serverDomain = searchParams.get('server_domain')


  const {data: configData, isLoading: isLoadingVisualConfig} =
    useGetVisualConfig()

  const {data: rpaConfig, isLoading: isLoadingConfigRpa} = useGetConfigRpa({
    rpaId,
    enabled: true,
  })
  const {
    data: historyData,
    dataUpdatedAt,
    isLoading: isLoadingHistory,
    isFetching,
  } = useGetHistory(historyParams, {
    onSuccess: (data: IResponseHistory) => {
      setHistoryParams((old) => ({...old, total: data.total}))
    },
  })
  const {isLoading: isLoadingNextExecution} = useGetNextExecution()
  const {isLoading: isLoadingSteps} = useGetSteps()

  const isLoading = useMemo(() => {
    return (
      isLoadingConfigRpa ||
      isLoadingHistory ||
      isLoadingNextExecution ||
      isLoadingSteps ||
      isFetching
    )
  }, [
    isLoadingConfigRpa,
    isLoadingHistory,
    isLoadingNextExecution,
    isLoadingSteps,
  ])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log(newValue)
    setValue(newValue)
  }

  
  const renderUpdatedLast = () => {
    if (value === 'configs') {
      return rpaConfig?.updatedLast
    }

    const dateHistory = new Date(dataUpdatedAt).toString()

    return moment(dateHistory).format('DD/MM/YYYY HH:mm:ss')
  }

  if (isLoadingVisualConfig) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
          padding: 24,
        }}>
        <CircularProgress size={50} />
      </div>
    )
  }

  return (
    <S.Wrapper>
      <HeaderContent
        title={configData.title}
        updatedLast={renderUpdatedLast()}
      />

      <S.WrapperTabs>
        <If condition={isAdmin}>
          <TabsMUI value={value} onChange={handleChange}>
            <Tab value="integration" label="Integrações" />

            <Tab
              value="configs"
              label="Configurações"
              style={{marginLeft: 32}}
            />
            <Tab
              value="business"
              label="Regras de Negócio"
              style={{marginLeft: 32}}
            />
          </TabsMUI>
        </If>

        <If condition={!isAdmin}>
          <TabsMUI value={value} onChange={handleChange}>
            <Tab value="integration" label="Integrações" />
          </TabsMUI>
        </If>
      </S.WrapperTabs>

      <S.Content>
        <If condition={isLoading}>
          <Box
            display="flex"
            alignItems={'center'}
            justifyContent="center"
            flex={1}
            style={{height: '100%'}}
            mt="5">
            <CircularProgress size={50} />
          </Box>
        </If>
        <If condition={!isLoading}>
          <If condition={value === 'integration'}>
            <IntegrationsContainter
              setHistoryParams={setHistoryParams}
              historyParams={historyParams}
              historyData={historyData}
              configData={configData}
            />
          </If>

          <If condition={value === 'configs'}>
            <ConfigContainer rpaConfig={rpaConfig} />
          </If>
          <If condition={value === 'business'}>
            <BusinessContainer rpaConfig={rpaConfig} />
          </If>
        </If>
      </S.Content>
    </S.Wrapper>
  )
}

export default ScheduledPage
