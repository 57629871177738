import {COLORS} from '@intraversa-lab/styleguide'
import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #dfe4e9;
  background: #ffffff;

  padding: 28px 24px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  h3,
  p {
    padding: 0px;
    margin: 0px;
  }

  h3 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: ${COLORS.shade[10]};
  }

  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    color: ${COLORS.neutral[70]};
    margin-top: 4px;
  }
`
