import {styled} from 'styled-components'

export const Wrapper = styled.div``

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eff2f4;
  padding-bottom: 32px;
  margin-bottom: 32px;

  .wrapper-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`
