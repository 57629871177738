import {styled} from 'styled-components'

export const Wrapper = styled.div`
  padding: 24px 32px 0px;
  box-sizing: border-box;
`

export const ContainerHeader = styled.div`
  display: flex;
`

export const TitleContent = styled.div`
  margin-top: 32px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div.title-left {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  > div.title-right {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`
