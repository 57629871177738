/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback} from 'react'

import {
  TextField,
  ButtonPortico,
  Modal,
  useConfirmModal,
  toast,
} from '@intraversa-lab/styleguide'
import {usePostTestConnection} from 'domains'

import * as SContainerCollapse from '../../styles'

import * as S from './styles'

interface IModalEditConnectivityEmailProps {
  isOpen: boolean
  onClose: () => void
}

export const ModalEditConnectivityEmail: React.FC<
  IModalEditConnectivityEmailProps
> = ({isOpen, onClose}) => {
  const {renderModal, updateModalStateAndOpenModal} = useConfirmModal()
  const {onTestConnection, isLoading} = usePostTestConnection({
    onError: (err) => {
      const code500 = String(err).includes('500')
      toast({
        text: 'Erro ao enviar email',
        description: code500
          ? 'Limite de email enviados excedidos'
          : 'Erro inexperado',
        type: 'error',
      })
    },
    onSuccess: () => {
      toast({
        text: 'Email enviado com sucesso',
        type: 'success',
      })
      onClose()
    },
  })
  const [email, setEmail] = React.useState('')

  const onSaveChanges = useCallback(() => {
    updateModalStateAndOpenModal({
      title: `Teste envio de email`,
      description: `Você deseja continuar com o teste?`,
      onConfirm: () => {
        onTestConnection({
          typeConfig: 'email',
          email,
        })
      },
      type: 'light',
      maxWidth: 409,
    })
  }, [email])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px'}>
        <S.Container>
          <div className="Container--Header">
            <S.Title>Teste conectividade</S.Title>
            <S.Description>
              Preencha o campo abaixo para receber o email
            </S.Description>
          </div>

          <S.WrapperInputs>
            <SContainerCollapse.WrapperInput>
              <SContainerCollapse.TextLg className="labelInput">
                Email
              </SContainerCollapse.TextLg>
              <TextField
                showIconPassword={false}
                placeholder="example@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </SContainerCollapse.WrapperInput>
          </S.WrapperInputs>

          <S.WrapperButtons>
            <ButtonPortico variant="outlined" onClick={onClose}>
              Cancelar
            </ButtonPortico>
            <ButtonPortico
              onClick={onSaveChanges}
              disabled={!email || isLoading}>
              Enviar email
            </ButtonPortico>
          </S.WrapperButtons>
        </S.Container>
      </Modal>

      {renderModal()}
    </>
  )
}
