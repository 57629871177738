import {Text, ButtonBack, Tag} from '@intraversa-lab/styleguide'
import {IconRefetch} from 'assets/IconRefetch'
import {navigateToUrl} from 'single-spa'

import {NotificationComponent} from '../Notifications'

import * as S from './styled'

interface IHeaderContent {
  updatedLast: string
  title: string
}

export const HeaderContent = ({updatedLast, title}: IHeaderContent) => {
  const onBack = () => {
    navigateToUrl('/')
  }

  return (
    <S.Wrapper>
      <S.ContainerHeader>
        <ButtonBack onClick={onBack} />
        {/* <NotificationComponent /> */}
      </S.ContainerHeader>

      <S.TitleContent>
        <div className="title-left">
          <Text type="headlineLg" style={{fontWeight: 600}}>
            {title}
          </Text>

          <Tag label="Ativo" variant="success" />
        </div>

        <div className="title-right">
          <IconRefetch />
          <Text type="labelLg">Última atualização</Text>
          <Text type="labelLg">{updatedLast || '20/11/2023 09:34:01'}</Text>
        </div>
      </S.TitleContent>
    </S.Wrapper>
  )
}
