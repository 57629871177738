/* eslint-disable @typescript-eslint/no-explicit-any */
import {QueryKeys} from 'infra'
import {QueryKey, UseQueryOptions, useQuery} from 'react-query'

import {emailService} from '../emailServices'
import {IResponseEmailAttachements} from '../emailTypes'

interface IUseEmailAttachments
  extends UseQueryOptions<unknown, unknown, unknown, QueryKey> {
  emailId: string
}

export function useGetEmailAttachments({
  emailId,
  ...useQueryOptions
}: IUseEmailAttachments) {
  return useQuery<IResponseEmailAttachements[]>({
    queryKey: [QueryKeys.GetEmailAttachments, {emailId}],
    queryFn: async () => {
      return emailService.getEmailAttachments({emailId})
    },
    ...(useQueryOptions as any),
  })
}
