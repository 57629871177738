import {api} from 'configs'
import {endpoinstConneciton} from 'infra'

import {IPayloadTestConnection} from './connectionTypes'

const postTestConnection = async ({
  typeConfig,
  email,
}: IPayloadTestConnection) => {
  const isEmail = typeConfig === 'email'

  if (isEmail) {
    return await api.post(
      `${endpoinstConneciton.postTestEmail}`,
      {},
      {
        params: {
          recipientEmail: email,
        },
      },
    )
  }

  return await api.post(
    `${endpoinstConneciton.postConnectionTest.replace(':typeConfig', typeConfig)}`,
  )
}

export const connectionApi = {
  postTestConnection,
}
