/* eslint-disable @typescript-eslint/no-explicit-any */
import {QueryKeys, TUseQueryTyped} from 'infra'
import {useQuery} from 'react-query'

import {rpaRunService} from '../rpaRunService'
import {IParamsGetHistory, IResponseHistory} from '../rpaRunTypes'

export const useGetHistory: TUseQueryTyped<
  IParamsGetHistory,
  IResponseHistory
> = ({page, offset}, options) => {
  return useQuery({
    queryKey: [QueryKeys.GetHistory, {page, offset}],
    queryFn: async () => {
      return rpaRunService.getHistory({page, offset})
    },
    staleTime: 1000 * 30,
    ...options,
  })
}
