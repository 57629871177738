/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState, useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { ButtonPortico, If, Text, toast } from '@intraversa-lab/styleguide'
import { Accordion, Box, CircularProgress } from '@mui/material'
import { apiUrl } from '../../../../configs'
import { IRpaConfigResponse } from 'domains/RpaConfig/rpaConfigTypes'
import { useForm, FormProvider, useWatch, Controller } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import {
  InputSelectdRHF,
  TProps,
} from '../../../../components/FormRHF/InputSelectRHF'
import { TextFieldRHF } from '../../../../components/FormRHF/TextFieldRHF'

import { businessSchema, BusinessSchema } from './businessSchema'
import * as S from './styles'
import axios from 'axios'
import { ModalConnectivitySimples } from 'components/ContainerCollapse/components/ModalConnectivity'
import { InputSelect } from 'components/InputSelect'
import { boolean } from 'zod'

const defaultTProps: TProps = {
  value: '',
  label: '',
}

const defaultParcelaTProps: TProps = {
  value: '',
  label: '',
}

interface LogicProductsDto {
  id: string
  productBitrixId: string
  productBitrixDescription: string
  productOmieId: string
  productOmieDescription: string
  type: string
}

interface LogicBusinessParamsDto {
  pipeline: TProps
  pipelineOptions: TProps[]
  stage: TProps
  stageOptions: TProps[]
  saleOptions: TProps
  documentCustomField: TProps
  endereco: TProps
  estado: TProps
  cidade: TProps
  bairro: TProps
  cep: TProps
  complemento: TProps
  numero: TProps
  documentCustomFieldOptions: TProps[]
  currentAccount: TProps
  currentAccountOptions: TProps[]
  billingDays: string
  effectiveDays: string
  productOptions: TProps[]
  products: LogicProductsDto[]
  dealsCustomFields: TProps[]
  salesCustom: TProps[]
  omieSaleFields: TProps[]
  //
  vendedoresChoose: LogicProductsDto[]
  vendedoresOptions: TProps[]
  campoVendedor: TProps
  campoParcela: TProps
  parcelasChoose: LogicProductsDto[]
}

interface IConfigContainer {
  rpaConfig?: IRpaConfigResponse
}

const optionsType: TProps[] = [
  { label: 'Contrato', value: 'CONTRATO' },
  { label: 'Ordem de Serviço', value: 'OS' },
]

const optionsParcela: TProps[] = [{ label: '', value: '' }]

type CardSelectedType = IRpaConfigResponse['config']['outbounds'][0]

export const BusinessContainer = ({ rpaConfig }: IConfigContainer) => {
  const [searchParams] = useSearchParams()

  const methods = useForm<BusinessSchema>({
    resolver: zodResolver(businessSchema),
    defaultValues: {
      stage: '',
      documentCustomField: '',
      currentAccount: '',
      billingDays: '',
      effectiveDays: '',
      endereco: '',
      estado: '',
      cidade: '',
      bairro: '',
      cep: '',
      complemento: '',
      numero: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
    control,
  } = methods

  console.log(errors)

  const [data, setData] = useState<LogicBusinessParamsDto | null>(null)

  const [loading, setLoading] = useState<boolean>({} as boolean)

  const [loadingStages, setLoadingStages] = useState<boolean>({} as boolean)

  const [loadingParcelas, setLoadingParcelas] = useState<boolean>(false)

  const [loadingVendedores, setLoadingVendedores] = useState<boolean>(false)

  const [loadingModal, setLoadingModal] = useState<boolean>(false)

  const [formProductData, setFormProductData] = useState<LogicProductsDto[]>([])

  const [formSalesMan, setformSalesMan] = useState<LogicProductsDto[]>([])

  const [formParcelas, setParcelas] = useState<LogicProductsDto[]>([])

  const [formStages, setStages] = useState<TProps[]>([])
  
  const [formPipeline, setPipeline] = useState<TProps>(defaultTProps)

  const [formSalesManChoose, setformSalesManChoose] =
    useState<TProps>(defaultTProps)

  const [formParcelasChoose, setformParcelasChoose] =
    useState<TProps>(defaultParcelaTProps)

  const [formParcelasChooseOmie, setformParcelasChooseOmie] =
    useState<TProps[]>(optionsParcela)

  const onSubmit = async () => {
    let request: LogicBusinessParamsDto = {
      pipeline: undefined as any,
      pipelineOptions: data.pipelineOptions,
      stage: undefined as any,
      stageOptions: data.stageOptions,
      documentCustomField: undefined as any,
      endereco: undefined as any,
      estado: undefined as any,
      cidade: undefined as any,
      bairro: undefined as any,
      cep: undefined as any,
      complemento: undefined as any,
      numero: undefined as any,
      campoParcela: undefined as any,
      campoVendedor: undefined as any,
      documentCustomFieldOptions: data.documentCustomFieldOptions,
      currentAccount: undefined as any,
      currentAccountOptions: data.currentAccountOptions,
      billingDays: '',
      effectiveDays: '',
      productOptions: data.productOptions,
      saleOptions: undefined as any,
      products: [],
      dealsCustomFields: [],
      vendedoresOptions: [],
      salesCustom: [],
      omieSaleFields: [],
      vendedoresChoose: [],
      parcelasChoose: [],
    }

    data.pipelineOptions.forEach((p) => {
      if (p.value === formPipeline.value) {
        request.pipeline = p
      }
    })

    formStages.forEach((p) => {
      if (p.value === control._formValues.stage) {
        request.stage = p
      }
    })

    data.documentCustomFieldOptions.forEach((p) => {
      if (p.value === control._formValues.documentCustomField) {
        request.documentCustomField = p
      }
    })

    data.documentCustomFieldOptions.forEach((p) => {
      if (p.value === control._formValues.endereco) {
        request.endereco = p
      }
    })

    data.documentCustomFieldOptions.forEach((p) => {
      if (p.value === control._formValues.estado) {
        request.estado = p
      }
    })

    data.documentCustomFieldOptions.forEach((p) => {
      if (p.value === control._formValues.cidade) {
        request.cidade = p
      }
    })

    data.documentCustomFieldOptions.forEach((p) => {
      if (p.value === control._formValues.bairro) {
        request.bairro = p
      }
    })

    data.documentCustomFieldOptions.forEach((p) => {
      if (p.value === control._formValues.cep) {
        request.cep = p
      }
    })

    data.documentCustomFieldOptions.forEach((p) => {
      if (p.value === control._formValues.complemento) {
        request.complemento = p
      }
    })

    data.documentCustomFieldOptions.forEach((p) => {
      if (p.value === control._formValues.numero) {
        request.numero = p
      }
    })

    data.currentAccountOptions.forEach((p) => {
      if (p.value === control._formValues.currentAccount) {
        request.currentAccount = p
      }
    })

    data.dealsCustomFields.forEach((p) => {
      if (p.value === formSalesManChoose.value) {
        request.campoVendedor = p
      }
    })

    data.dealsCustomFields.forEach((p) => {
      if (p.value === formParcelasChoose.value) {
        request.campoParcela = p
      }
    })

    request.vendedoresChoose = formSalesMan
    request.parcelasChoose = formParcelas
    request.billingDays = control._formValues.billingDays
    request.effectiveDays = control._formValues.effectiveDays
    request.products = formProductData

    try {
      setLoading(true)

      const response = await axios.post(
        `${apiUrl.url}/rpa/logic/params`,
        // `http://localhost:8080/api/rpa/logic/params`,
        request,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
          },
        },
      )
      toast({
        type: 'success',
        text: 'Parametros salvos com sucesso.',
        description: 'Você pode testar a conexão agora.',
      })
      setLoading(false)
      console.log('Response:', response.data)
    } catch (error) {
      toast({
        type: 'error',
        text: 'Problema ao salvar parametros.',
        description: 'Você pode testar a conexão agora.',
      })
      setLoading(false)
      console.error(
        'Error:',
        error.response ? error.response.data : error.message,
      )
    }
  }

  const refreshProducts = async () => {
    try {
      setLoading(true)

      const response = await axios.post(
        // `http://localhost:8080/api/rpa/logic/refresh-products`,
        `${apiUrl.url}/rpa/logic/refresh-products`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
          },
        },
      )
      toast({
        type: 'success',
        text: 'Parametros salvos com sucesso.',
        description: 'Você pode testar a conexão agora.',
      })
      setLoading(false)
      useEffect(() => {
        const fetchData = async () => {
          setLoading(true)
    
          const responseOptions = await fetch(
            // `http://localhost:8080/api/rpa/logic/params`,
            `${apiUrl.url}/rpa/logic/params`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
              },
            },
          )
    
          const result: LogicBusinessParamsDto = await responseOptions.json()
    
          setData(result)
    
          setValue('stage', result.stage.value)
          setValue('documentCustomField', result.documentCustomField?.value)
          setValue('endereco', result.endereco?.value ?? '')
          setValue('estado', result.estado?.value ?? '')
          setValue('cidade', result.cidade?.value ?? '')
          setValue('bairro', result.bairro?.value ?? '')
          setValue('cep', result.cep?.value ?? '')
          setValue('complemento', result.complemento?.value ?? '')
          setValue('numero', result.numero?.value ?? '')
          setValue('currentAccount', result.currentAccount.value)
          setValue('billingDays', result.billingDays)
          setValue('effectiveDays', result.effectiveDays)
    
          setFormProductData(result.products)
          setPipeline(result.pipeline)
    
          setStages(result.stageOptions)
    
        
    
          if (result.campoVendedor !== null) {
            setformSalesManChoose(result.campoVendedor)
            if (result.vendedoresChoose.length <= 0) {
              handleSalesChange(result.campoVendedor)
            } else {
              setformSalesMan(result.vendedoresChoose)
            }
          }
    
          if (result.campoParcela !== null) {
            setformParcelasChoose(result.campoParcela)
            if (result.parcelasChoose.length <= 0) {
              handleParcelasChange(result.campoParcela)
            } else {
              setParcelas(result.parcelasChoose)
            }
          }
          setLoading(false)
        }
    
        fetchData()
      }, [])
    } catch (error) {
      toast({
        type: 'error',
        text: 'Problema ao salvar parametros.',
        description: 'Você pode testar a conexão agora.',
      })
      setLoading(false)
      console.error(
        'Error:',
        error.response ? error.response.data : error.message,
      )
    }
  }

  const handleProductChangeOmie = (index, e) => {
    formProductData[index].productOmieId = e.value
    formProductData[index].productOmieDescription = e.label
    setFormProductData(formProductData)
  }

  const handleChangePipeline = async (e) => {
    const value = e.value
    setPipeline(e)
    setLoadingParcelas(true)
    try {
      const responseOptions = await fetch(
        // `http://localhost:8080/api/v1/bitrix/enrichment/deal-custom-fields-list/${e.label}`,
        `${apiUrl.url}/v1/bitrix/enrichment/pipeline/${value}/stage`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )

      const result: TProps[] = await responseOptions.json()

      setStages(result)
      setLoadingParcelas(false)
    } catch (e) {
      setLoadingParcelas(false)
      toast({
        type: 'error',
        text: 'Problema ao buscar stages.',
        description: 'Você pode testar a conexão agora.',
      })
    }
  }

  const handleSalesChange = async (e) => {
    console.log(e)

    formSalesManChoose.value = e.value
    formSalesManChoose.label = e.label

    setformSalesManChoose(formSalesManChoose)

    setLoadingVendedores(true)

    try {
      const responseOptions = await fetch(
        // `http://localhost:8080/api/v1/bitrix/enrichment/deal-custom-fields-list/${e.label}`,
        `${apiUrl.url}/v1/bitrix/enrichment/deal-custom-fields-list/${e.label}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )

      console.log(responseOptions)

      const result: TProps[] = await responseOptions.json()

      let vendedoresBitrix: LogicProductsDto[] = []

      console.log(result)

      try {
        result.forEach((e) => {
          console.log(e)

          const vendedor: LogicProductsDto = {
            id: '',
            productBitrixId: e.label,
            productBitrixDescription: e.value,
            productOmieId: '',
            productOmieDescription: '',
            type: '',
          }

          vendedoresBitrix.push(vendedor)
        })
      } catch (e) {
        console.log(e)
      }

      console.log(vendedoresBitrix)

      setformSalesMan(vendedoresBitrix)
    } catch (e) {
      setLoadingVendedores(false)
    } finally {
      setLoadingVendedores(false)
    }
  }

  const handleParcelasChange = async (e) => {
    console.log(e)

    formParcelasChoose.value = e.value
    formParcelasChoose.label = e.label

    setformParcelasChoose(formParcelasChoose)

    setLoadingParcelas(true)

    try {
      const responseOptions = await fetch(
        // `http://localhost:8080/api/v1/bitrix/enrichment/deal-custom-fields-list/${e.label}`,
        `${apiUrl.url}/v1/bitrix/enrichment/deal-custom-fields-list/${e.label}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )

      console.log(responseOptions)

      const result: TProps[] = await responseOptions.json()

      let parcelasBitrix: LogicProductsDto[] = []

      console.log(result)

      try {
        result.forEach((e) => {
          console.log(e)

          const parcela: LogicProductsDto = {
            id: '',
            productBitrixId: e.label,
            productBitrixDescription: e.value,
            productOmieId: '',
            productOmieDescription: '',
            type: '',
          }

          parcelasBitrix.push(parcela)
        })
      } catch (e) {
        console.log(e)
      }

      console.log(parcelasBitrix)

      setParcelas(parcelasBitrix)
    } catch (e) {
      setLoadingParcelas(false)
    } finally {
      setLoadingParcelas(false)
    }
  }

  const handleTypeChangeOmie = (index, e) => {
    formProductData[index].type = e.value
    setFormProductData(formProductData)
  }

  const handleVendedorChangeOmie = (index, e) => {
    console.log(e) // Log do valor selecionado

    const updatedVendedores = [...formSalesMan] // Cria uma cópia do array
    updatedVendedores[index].productOmieId = e.value
    updatedVendedores[index].productOmieDescription = e.label

    setformSalesMan(updatedVendedores)

    console.log(updatedVendedores) // L
  }

  const handleParcelaChangeOmie = async (e, index) => {
    console.log(e)
    const eValue = String(e)

    if (eValue.length > 2) {
      try {
        const responseOptions = await fetch(
          // `http://localhost:8080/api/rpa/logic/parcelas?param=${eValue}`,
          `${apiUrl.url}/rpa/logic/parcelas?param=${eValue}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
          },
        )

        const result: TProps[] = await responseOptions.json()
        setformParcelasChooseOmie(result)
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    }
  }

  const handleParcelaChangeOmieSelect = (index, e) => {
    console.log(e) // Log do valor selecionado

    // Atualizando o item específico dentro do estado 'formParcelas'
    const updatedParcelas = [...formParcelas] // Cria uma cópia do array
    updatedParcelas[index].productOmieId = e.value
    updatedParcelas[index].productOmieDescription = e.label

    // Atualizando o estado 'parcelas'
    setParcelas(updatedParcelas)

  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const responseOptions = await fetch(
        // `http://localhost:8080/api/rpa/logic/params`,
        `${apiUrl.url}/rpa/logic/params`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )

      const result: LogicBusinessParamsDto = await responseOptions.json()

      setData(result)

      setValue('stage', result.stage.value)
      setValue('documentCustomField', result.documentCustomField?.value)
      setValue('endereco', result.endereco?.value ?? '')
      setValue('estado', result.estado?.value ?? '')
      setValue('cidade', result.cidade?.value ?? '')
      setValue('bairro', result.bairro?.value ?? '')
      setValue('cep', result.cep?.value ?? '')
      setValue('complemento', result.complemento?.value ?? '')
      setValue('numero', result.numero?.value ?? '')
      setValue('currentAccount', result.currentAccount.value)
      setValue('billingDays', result.billingDays)
      setValue('effectiveDays', result.effectiveDays)

      setFormProductData(result.products)
      setPipeline(result.pipeline)

      setStages(result.stageOptions)

    

      if (result.campoVendedor !== null) {
        setformSalesManChoose(result.campoVendedor)
        if (result.vendedoresChoose.length <= 0) {
          handleSalesChange(result.campoVendedor)
        } else {
          setformSalesMan(result.vendedoresChoose)
        }
      }

      if (result.campoParcela !== null) {
        setformParcelasChoose(result.campoParcela)
        if (result.parcelasChoose.length <= 0) {
          handleParcelasChange(result.campoParcela)
        } else {
          setParcelas(result.parcelasChoose)
        }
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  return (
    <S.Wrapper>
      <If condition={loading}>
        <Box
          display="flex"
          alignItems={'center'}
          justifyContent="center"
          flex={1}
          style={{ height: '100%' }}
          mt="5"
        >
          <CircularProgress size={50} />
        </Box>
      </If>
      <If condition={!loading}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p
              style={{
                fontFamily: 'Roboto',
                fontWeight: 600,
                fontSize: '24px',
                color: '#161F28',
                margin: 0,
                padding: 0,
                marginTop: 32,
                marginLeft: 16,
              }}
            >
              Parâmetros do negócio
            </p>
            <Text
              type="bodyLg"
              marginTop={'8px'}
              marginBottom={'24px'}
              marginLeft={'16px'}
              style={{ color: '#59636B' }}
            >
              Selecione uma execução e visualize seus detalhes em etapas
            </Text>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ButtonPortico
                type="submit"
                style={{ marginLeft: '16px', maxWidth: '320px', width: '100%' }}
              >
                Salvar Parametros
              </ButtonPortico>
            </div>

            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Pipeline Bitrix</span>
                  <span>Pipeline usado pela integração</span>
                </div>

                <div style={{ width: '100%' }}>
                  {/* <InputSelectdRHF
                    placeholder={'Selecione a permissão'}
                    options={data?.pipelineOptions || []}
                    name="pipeline"
                    isError={!!errors?.stage}
                    onInputCapture={(e) => {
                      console.log(e)
                      handleChangePipeline(e)
                    }}
                  /> */}
                  <InputSelect
                    key="pipeline"
                    name="pipeline"
                    value={data?.pipelineOptions?.find(
                      (item) =>
                        String(item.value) === String(formPipeline.value),
                    )}
                    placeholder={'Selecione o pipeline'}
                    options={data?.pipelineOptions || []}
                    onChange={(e) => {
                      handleChangePipeline(e)
                    }}
                  />
                </div>
                <div className="info">
                  <span>Stage Bitrix</span>
                  <span>Coluna do Kanban base da Integração</span>
                </div>

                <div style={{ width: '100%' }}>
                  <If condition={loadingParcelas}>
                    <Box
                      display="flex"
                      alignItems={'center'}
                      justifyContent="center"
                      flex={1}
                      style={{ height: '100%' }}
                      mt="5"
                    >
                      <CircularProgress size={50} />
                    </Box>
                  </If>
                  <If condition={!loadingParcelas}>
                    <InputSelectdRHF
                      placeholder={'Selecione o stage'}
                      options={formStages || []}
                      name="stage"
                      isError={!!errors?.stage}
                    />
                  </If>
                </div>
              </div>
            </S.ContentDescription>
            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Dia de Faturamento</span>
                  <span>Dia fixo de faturamento</span>
                </div>

                <div style={{ width: '100%' }}>
                  <TextFieldRHF
                    placeholder="Dia fixo de faturamento"
                    control={control}
                    name="billingDays"
                  />
                </div>
                <div className="info">
                  <span>Dias de Vigência</span>
                  <span>Tempo total de vigência em dias</span>
                </div>

                <div style={{ width: '100%' }}>
                  <TextFieldRHF
                    placeholder="Dia fixo de faturamento"
                    control={control}
                    name="effectiveDays"
                  />
                </div>
              </div>
            </S.ContentDescription>
            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Bitrix Campo CNPJ</span>
                  <span>Custom Field Bitrix</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione o custom field'}
                    options={data?.documentCustomFieldOptions || []}
                    name="documentCustomField"
                    isError={!!errors?.stage}
                  />
                </div>

                <div className="info">
                  <span>Conta Corrente Omie</span>
                  <span>Id da conta corrente</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione a conta corrente'}
                    options={data?.currentAccountOptions || []}
                    name="currentAccount"
                    isError={!!errors?.stage}
                  />
                </div>
              </div>
            </S.ContentDescription>

            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Campo Endereço</span>
                  <span>Custom Field Bitrix</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione o custom field'}
                    options={data?.documentCustomFieldOptions || []}
                    name="endereco"
                    isError={!!errors?.stage}
                  />
                </div>

                <div className="info">
                  <span>Campo Estado</span>
                  <span>Custom Field Bitrix</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione o custom field'}
                    options={data?.documentCustomFieldOptions || []}
                    name="estado"
                    isError={!!errors?.stage}
                  />
                </div>
              </div>
            </S.ContentDescription>

            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Campo Cidade</span>
                  <span>Custom Field Bitrix</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione o custom field'}
                    options={data?.documentCustomFieldOptions || []}
                    name="cidade"
                    isError={!!errors?.stage}
                  />
                </div>

                <div className="info">
                  <span>Campo Bairro</span>
                  <span>Custom Field Bitrix</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione o custom field'}
                    options={data?.documentCustomFieldOptions || []}
                    name="bairro"
                    isError={!!errors?.stage}
                  />
                </div>
              </div>
            </S.ContentDescription>

            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Campo Cep</span>
                  <span>Custom Field Bitrix</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione o custom field'}
                    options={data?.documentCustomFieldOptions || []}
                    name="cep"
                    isError={!!errors?.stage}
                  />
                </div>

                <div className="info">
                  <span>Campo Complemento</span>
                  <span>Custom Field Bitrix</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione o custom field'}
                    options={data?.documentCustomFieldOptions || []}
                    name="complemento"
                    isError={!!errors?.stage}
                  />
                </div>
              </div>
            </S.ContentDescription>

            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Campo Numero</span>
                  <span>Custom Field Bitrix</span>
                </div>

                <div style={{ width: '100%' }}>
                  <InputSelectdRHF
                    placeholder={'Selecione o custom field'}
                    options={data?.documentCustomFieldOptions || []}
                    name="numero"
                    isError={!!errors?.stage}
                  />
                </div>
              </div>
            </S.ContentDescription>

            <p
              style={{
                fontFamily: 'Roboto',
                fontWeight: 600,
                fontSize: '24px',
                color: '#161F28',
                margin: 0,
                padding: 0,
                marginTop: 32,
                marginLeft: 16,
              }}
            >
              De Para de Parcelas
            </p>
            <Text
              type="bodyLg"
              marginTop={'8px'}
              marginBottom={'24px'}
              marginLeft={'16px'}
              style={{ color: '#59636B' }}
            >
              Escolha a lista de custom field das parcelas
            </Text>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '50%', marginLeft: '16px' }}>
                <InputSelect
                  key={'bitrixparcelakey'}
                  name={'bitrixparcelaname'}
                  value={data?.dealsCustomFields?.find(
                    (item) =>
                      String(item.value) === String(formParcelasChoose.value),
                  )}
                  placeholder={'Selecione o custom field bitrix'}
                  options={data?.dealsCustomFields || []}
                  isError={!!errors?.['' + 'type']}
                  onChange={(e) => {
                    handleParcelasChange(e)
                  }}
                />
              </div>
            </div>

            <If condition={loadingParcelas}>
              <Box
                display="flex"
                alignItems={'center'}
                justifyContent="center"
                flex={1}
                style={{ height: '100%' }}
                mt="5"
              >
                <CircularProgress size={50} style={{ marginTop: '16px' }} />
              </Box>
            </If>

            <If condition={!loadingParcelas}>
              {formParcelas.map((parcelas, index) => (
                <S.ContentDescription>
                  <div className="wrapper">
                    <div className="info">
                      <span>
                        Parcela Bitrix - {parcelas.productBitrixDescription}
                      </span>
                      <span>
                        Parcela ID Bitrix - {parcelas.productBitrixId}
                      </span>
                    </div>
                    <div style={{ width: '100%' }}>
                      <InputSelect
                        key={parcelas.productBitrixDescription + 'omieparcela'}
                        name={parcelas.productBitrixId + 'omieparcela'}
                        value={formParcelas[index].productOmieDescription}
                        placeholder={
                          formParcelas[index].productOmieDescription === ''
                            ? 'Digite a parcela omie'
                            : formParcelas[index].productOmieDescription
                        }
                        options={formParcelasChooseOmie || []}
                        isError={!!errors?.['type']}
                        onInputChange={(e) => {
                          handleParcelaChangeOmie(e, index)
                        }}
                        onChange={(e) => {
                          handleParcelaChangeOmieSelect(index, e)
                        }}
                      />
                    </div>
                  </div>
                </S.ContentDescription>
              ))}
            </If>
            <p
              style={{
                fontFamily: 'Roboto',
                fontWeight: 600,
                fontSize: '24px',
                color: '#161F28',
                margin: 0,
                padding: 0,
                marginTop: 32,
                marginLeft: 16,
              }}
            >
              De Para de Vendedores
            </p>
            <Text
              type="bodyLg"
              marginTop={'8px'}
              marginBottom={'24px'}
              marginLeft={'16px'}
              style={{ color: '#59636B' }}
            >
              Escolha a lista de custom field do vendedor
            </Text>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '50%', marginLeft: '16px' }}>
                <InputSelect
                  key={'bitrixsalekey'}
                  name={'bitrixsalename'}
                  value={data?.dealsCustomFields?.find(
                    (item) =>
                      String(item.value) === String(formSalesManChoose.value),
                  )}
                  placeholder={'Selecione o custom field bitrix'}
                  options={data?.dealsCustomFields || []}
                  isError={!!errors?.['' + 'type']}
                  onChange={(e) => {
                    handleSalesChange(e)
                  }}
                />
              </div>
            </div>

            <If condition={loadingVendedores}>
              <Box
                display="flex"
                alignItems={'center'}
                justifyContent="center"
                flex={1}
                style={{ height: '100%' }}
                mt="5"
              >
                <CircularProgress size={50} style={{ marginTop: '16px' }} />
              </Box>
            </If>

            <If condition={!loadingVendedores}>
              {formSalesMan.map((sales, index) => (
                <S.ContentDescription>
                  <div className="wrapper">
                    <div className="info">
                      <span>
                        Vendedor Bitrix - {sales.productBitrixDescription}
                      </span>
                      <span>Vendedor ID Bitrix - {sales.productBitrixId}</span>
                    </div>
                    <div style={{ width: '100%' }}>
                      <InputSelect
                        key={sales.productBitrixId + 'omiesale'}
                        name={sales.productBitrixDescription + 'omiesale'}
                        value={data.omieSaleFields.find(
                          (item) =>
                            String(item.value) === String(sales.productOmieId),
                        )}
                        placeholder={'Selecione o vendedor omie'}
                        options={data?.omieSaleFields || []}
                        isError={!!errors?.['type']}
                        onChange={(e) => {
                          handleVendedorChangeOmie(index, e)
                        }}
                      />
                    </div>
                  </div>
                </S.ContentDescription>
              ))}
            </If>
          </form>
          <p
            style={{
              fontFamily: 'Roboto',
              fontWeight: 600,
              fontSize: '24px',
              color: '#161F28',
              margin: 0,
              padding: 0,
              marginTop: 32,
              marginLeft: 16,
            }}
          >
            De Para de produtos
          </p>
          <Text
            type="bodyLg"
            marginTop={'8px'}
            marginBottom={'24px'}
            marginLeft={'16px'}
            style={{ color: '#59636B' }}
          >
            Coloque o tipo e o id do produto omie baseado nos produtos bitrix
          </Text>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ButtonPortico
              onClick={refreshProducts}
              style={{ marginLeft: '16px', maxWidth: '320px', width: '100%' }}
            >
              Atualizar Produtos
            </ButtonPortico>
          </div>
          {formProductData.map((product, index) => (
            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>
                    Produto Bitrix - {product.productBitrixDescription}
                  </span>
                  <span>ID Bitrix - {product.productBitrixId}</span>
                </div>

                <div style={{ width: '50%' }}>
                  <InputSelect
                    key={product.id + 'omie'}
                    name={product.id + 'omie'}
                    value={data?.productOptions?.find(
                      (item) =>
                        String(item.value) === String(product.productOmieId),
                    )}
                    placeholder={'Selecione o serviço omie'}
                    options={data?.productOptions || []}
                    isError={!!errors?.[product.id + 'type']}
                    onChange={(e) => {
                      handleProductChangeOmie(index, e)
                    }}
                  />
                </div>

                <div style={{ width: '50%' }}>
                  <InputSelect
                    key={product.id + 'type'}
                    name={product.id + 'type'}
                    value={optionsType.find(
                      (item) => String(item.value) === String(product.type),
                    )}
                    placeholder={'Selecione o tipo'}
                    options={optionsType || []}
                    isError={!!errors?.[product.id + 'type']}
                    onChange={(e) => {
                      handleTypeChangeOmie(index, e)
                    }}
                  />
                </div>
              </div>
            </S.ContentDescription>
          ))}
        </FormProvider>
      </If>
    </S.Wrapper>
  )
}
