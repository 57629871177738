/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback, useMemo, useState} from 'react';

import {ButtonPortico, Text, useConfirmModal} from '@intraversa-lab/styleguide';
import {Stack} from '@mui/material';
import {BreadcrumbItem, Breadcrumbs} from 'components/Breadcrumbs/Breadcrumbs';
import {CardChooseConfig} from 'components/CardChooseConfig';
import {CardIntegration} from 'components/ContainerCollapse/ContainerCollapse';
import {
  ENUM_TYPES_SCHEDULER_CONFIG,
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes,
} from 'components/ContainerCollapse/meta/schedulerConfigTypes';
import {useStopRPA} from 'domains/Rpa';
import {IRpaConfigResponse} from 'domains/RpaConfig/rpaConfigTypes';
import {usePostNow} from 'domains/RpaRun/useCases/usePostRunNow';
import {useParams, useSearchParams} from 'react-router-dom';

import * as S from './styles';

export const PauseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9375 11H7.0625V5H5.9375V11ZM8.9375 11H10.0625V5H8.9375V11ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38437 13.9781 2.70312 13.2969C2.02188 12.6156 1.48438 11.8188 1.09063 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.02188 3.36875 2.70312 2.69375C3.38437 2.01875 4.18125 1.48438 5.09375 1.09063C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49062 3.48125C2.24687 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24687 11.2656 3.49062 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
        fill="#161F28"
      />
      <path
        d="M5.9375 11H7.0625V5H5.9375V11ZM8.9375 11H10.0625V5H8.9375V11ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38437 13.9781 2.70312 13.2969C2.02188 12.6156 1.48438 11.8188 1.09063 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.02188 3.36875 2.70312 2.69375C3.38437 2.01875 4.18125 1.48438 5.09375 1.09063C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49062 3.48125C2.24687 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24687 11.2656 3.49062 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M5.9375 11H7.0625V5H5.9375V11ZM8.9375 11H10.0625V5H8.9375V11ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38437 13.9781 2.70312 13.2969C2.02188 12.6156 1.48438 11.8188 1.09063 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.02188 3.36875 2.70312 2.69375C3.38437 2.01875 4.18125 1.48438 5.09375 1.09063C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49062 3.48125C2.24687 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24687 11.2656 3.49062 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M5.9375 11H7.0625V5H5.9375V11ZM8.9375 11H10.0625V5H8.9375V11ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38437 13.9781 2.70312 13.2969C2.02188 12.6156 1.48438 11.8188 1.09063 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.02188 3.36875 2.70312 2.69375C3.38437 2.01875 4.18125 1.48438 5.09375 1.09063C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49062 3.48125C2.24687 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24687 11.2656 3.49062 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M5.9375 11H7.0625V5H5.9375V11ZM8.9375 11H10.0625V5H8.9375V11ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38437 13.9781 2.70312 13.2969C2.02188 12.6156 1.48438 11.8188 1.09063 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.02188 3.36875 2.70312 2.69375C3.38437 2.01875 4.18125 1.48438 5.09375 1.09063C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49062 3.48125C2.24687 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24687 11.2656 3.49062 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export const StartIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18125 11.1875L11.1875 8L6.18125 4.8125V11.1875ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38437 13.9781 2.70312 13.2969C2.02188 12.6156 1.48438 11.8188 1.09063 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.02188 3.36875 2.70312 2.69375C3.38437 2.01875 4.18125 1.48438 5.09375 1.09063C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49062 3.48125C2.24687 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24687 11.2656 3.49062 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};

interface IConfigContainer {
  rpaConfig?: IRpaConfigResponse;
}

type CardSelectedType = IRpaConfigResponse['config']['outbounds'][0];

export const ConfigContainer = ({rpaConfig}: IConfigContainer) => {
  const [searchParams] = useSearchParams()
  const {mutate: onRunNow} = usePostNow();
  const {stopRPA} = useStopRPA();
  const {rpaId} = useParams();
  const {renderModal, updateModalStateAndOpenModal} = useConfirmModal();

  const code = searchParams.get('code')
  const state = searchParams.get('state')
  const domain = searchParams.get('domain')
  const memberId = searchParams.get('member_id')
  const scope = searchParams.get('scope')
  const serverDomain = searchParams.get('server_domain')

  const [cardSelected, setCardSelected] = useState<CardSelectedType>(
    {} as CardSelectedType,
  );
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  console.log({cardSelected});

  const showDetailsConfig = useMemo(() => {
    return !!cardSelected?.name;
  }, [cardSelected]);

  const handleSelectCard = useCallback(
    (type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES, configId: string) => {
      const findConfig = rpaConfig?.config?.outbounds?.find(
        item => item.name === type && item.id === configId,
      );

      const informationsConfig = schedulerConfigTypes.find(
        config => config.type === type,
      );

      setBreadcrumbs([
        {
          name: 'Configurações',
          value: 'config',
        },
        {
          name: informationsConfig.title,
          value: findConfig.name,
        },
      ]);

      if (findConfig) {
        setCardSelected(findConfig);
      }
    },
    [rpaConfig],
  );

  const handleChangeBreadcrumbs = useCallback((value: string) => {
    if (value === 'config') {
      setBreadcrumbs([]);
      setCardSelected({} as CardSelectedType);
    }
  }, []);
  

  return (
    <S.Wrapper>
      {!showDetailsConfig && (
        <>
          <S.WrapperHeader>
            <Text type="headlineSm" fontWeight={600}>
              Scheduler
            </Text>

            <div className="wrapper-buttons">
              <ButtonPortico
                variant="outlined"
                startIcon={<PauseIcon />}
                onClick={() =>
                  updateModalStateAndOpenModal({
                    title: `Pausar integração`,
                    description: `Ao confirmar essa ação você irá pausar sua integração RPA Cadastro. Deseja continuar?`,
                    onConfirm: () => {
                      stopRPA({
                        companyId: rpaId,
                        userId: '',
                      });
                    },
                    type: 'light',
                    maxWidth: 417,
                  })
                }
              >
                Pausar Integração
              </ButtonPortico>

              <ButtonPortico
                startIcon={<StartIcon />}
                onClick={() =>
                  updateModalStateAndOpenModal({
                    title: `Execução manual`,
                    description: `Ao confirmar essa ação você irá forçar uma execução manual do sistema.  Deseja continuar?`,
                    onConfirm: () => {
                      onRunNow('');
                    },
                    type: 'light',
                    maxWidth: 417,
                  })
                }
              >
                Execução Manual
              </ButtonPortico>
            </div>
          </S.WrapperHeader>

          <Stack spacing={'16px'}>
            {rpaConfig?.config?.outbounds.map(config => (
              <CardChooseConfig
                key={config.id}
                type={config.name as ENUM_TYPES_SCHEDULER_CONFIG_VALUES}
                onSelected={type => handleSelectCard(type as any, config.id)}
                title={
                  (config as any)
                    .description as ENUM_TYPES_SCHEDULER_CONFIG_VALUES
                }
              />
            ))}
          </Stack>
          {code && handleSelectCard('BITRIX24', rpaConfig?.config?.outbounds[0].id)}
        </>
      )}

      {showDetailsConfig && (
        <>
          <Breadcrumbs
            items={breadcrumbs}
            active={cardSelected?.name}
            onChange={handleChangeBreadcrumbs}
          />

          <CardIntegration
            key={cardSelected.id}
            type={cardSelected.name as ENUM_TYPES_SCHEDULER_CONFIG_VALUES}
            title={
              (cardSelected as any)
                .description as ENUM_TYPES_SCHEDULER_CONFIG_VALUES
            }
            data={cardSelected.credential}
            configId={cardSelected.id}
          />
        </>
      )}

      {/* TODO: REGRA PARA MOSTRAR O CARDINTEGRATION */}

      {renderModal()}
    </S.Wrapper>
  );
};
