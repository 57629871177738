import {COLORS} from '@intraversa-lab/styleguide'
import {createTheme} from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.shade[10],
    },
    error: {
      main: COLORS.primary[50],
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          minHeight: 44,
          fontFamily: 'Roboto',
        },
        text: {
          color: COLORS.purple[10],
        },
        disabled: {
          background: COLORS.neutral[20],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 700,
          fontFamily: 'Roboto',
          color: COLORS.shade[10],
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          color: COLORS.shade[10],
          border: '0px !important',
          outline: '0px !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Montserrat',
          fontSize: 36,
          fontWeight: 400,
          color: COLORS.shade[10],
        },
        h6: {
          fontFamily: 'Roboto',
          fontSize: 16,
          fontWeight: 400,
          color: COLORS.neutral[70],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: COLORS.purple[10],
        },
        root: {
          color: COLORS.purple[10],
          fontFamily: 'Inter',
          padding: 0,
          borderTop: '1px solid #EFF2F4',
          borderBottom: '1px solid #EFF2F4',
        },
        flexContainer: {
          margin: 0,
          padding: 0,
        },
        scroller: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: COLORS.shade[10],
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          letter: '0.01em',
          padding: '20px 0px',
          textTransform: 'initial',
          margin: 0,
          '&.Mui-selected': {
            color: COLORS.purple[10],
          },
          '&:hover': {
            color: COLORS.purple[10],
          },
        },
      },
    },
  },
})
