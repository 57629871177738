// import { Select } from '@mui/material';
import { Select } from '@intraversa-lab/styleguide';
import { IInputProps, InputField } from './InputField/InputField'
import { InputSelect } from 'components/InputSelect'
import { Controller, UseControllerProps, FieldValues } from 'react-hook-form'

export type TProps = { label: string; value: string }

export function InputSelectdRHF<FormType extends FieldValues>({
  isError,
  options,
  name,
  rules,
  control,
  ...rest
}: {
  options: TProps[],
  name: string
} & IInputProps &
  UseControllerProps<FormType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <InputSelect
          {...rest}
          options={options}
          value={options.find((item) => String(item.value) === String(value))}
          onChange={(value: TProps)=> onChange(value.value)}
          isError={isError}
        />
      )}
    />
  )
}
