/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react'

import {
  TextField,
  ButtonPortico,
  Modal,
  If,
  toast,
} from '@intraversa-lab/styleguide'
import {Box, CircularProgress} from '@mui/material'
import {
  EMAIL_TYPE,
  ENUM_EMAIL_TYPE,
  ENUM_TYPES_SCHEDULER_CONFIG,
  ISchedulerConfigBase,
} from 'components/ContainerCollapse/meta/schedulerConfigTypes'
import {apiUrl} from 'configs'
import {IResponseEmailConfig, IResponseEmailDetail} from 'domains'
import {IRpaConfigResponse} from 'domains/RpaConfig/rpaConfigTypes'
import {useParams, useSearchParams} from 'react-router-dom'

import * as SContainerCollapse from '../../styles'
import { ModalConnectivitySimples} from '../ModalConnectivity'

import * as S from './styles'

interface IModalEditCredentialsProps {
  isOpen: boolean
  onClose: () => void
  data?: IRpaConfigResponse['config']['outbounds'][0]['credential']
  dataEmail?: IResponseEmailConfig & IResponseEmailDetail
  configSelected: ISchedulerConfigBase
  typeEmail?: ENUM_EMAIL_TYPE
}

export const ModalEditCredentials: React.FC<IModalEditCredentialsProps> = ({
  configSelected,
  data,
  isOpen,
  onClose,
  dataEmail,
  typeEmail,
}) => {
  const {rpaId} = useParams()
  const [showSavingCredentials, setSavingCredentials] = useState(false)
  const [showOauthConfig, setOauthConfig] = useState(false)
  const [showLoading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const state = searchParams.get('state')
  const domain = searchParams.get('domain')
  const memberId = searchParams.get('member_id')
  const scope = searchParams.get('scope')
  const serverDomain = searchParams.get('server_domain')

  const [formData, setFormData] = React.useState<any>((data?.data as any) || {})

  const handleInputChange = (name: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleCodeOauth = async () => {
    try {
      onClose()

      setLoading(true)

      const response = await fetch(
        `${apiUrl.url}/bitrix/authorize?code=${code}&state=${rpaId}&domain=${domain}&member_id=${memberId}&scope=${scope}&server_domain=${serverDomain}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )

      setLoading(false)
      if (!response.ok) {
        toast({
          type: 'error',
          text: 'Erro ao sincronizar credenciais.',
          description: 'Tente novamente em instantes.',
        })
        setLoading(false)
        setSavingCredentials(false)
        throw new Error('Failed to update credentials')
      }

      toast({
        type: 'success',
        text: 'Credenciais sincronizadas com sucesso.',
        description: 'Você pode testar a conexão agora.',
      })
      const result = await response.json()
      console.log('Success:', result)
      onClose()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  React.useEffect(() => {
    if (code && state && domain && memberId && scope && serverDomain) {
      handleCodeOauth()
      window.history.replaceState(null, '', window.location.pathname)
    }
  }, [code, state, domain, memberId, scope, serverDomain])

  const handleSave = async () => {
    try {
      data.data = formData

      if (configSelected?.type === ENUM_TYPES_SCHEDULER_CONFIG.BITRIX24) {
        setLoading(true)
        setSavingCredentials(true)

        const urlBitrix = `https://${formData.appUrl}/oauth/authorize/?client_id=${formData.clientId}&state=${rpaId}`

        onClose()

        const response = await fetch(
          `${apiUrl.url}/rpa/scheduler/rpa-config-credential/${rpaId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
            body: JSON.stringify(data),
          },
        )

        if (!response.ok) {
          toast({
            type: 'error',
            text: 'Erro ao salvar credenciais.',
            description: 'Tente novamente em instantes.',
          })
          setLoading(false)
          setSavingCredentials(false)
          throw new Error('Failed to update credentials')
        }

        toast({
          type: 'success',
          text: 'Credenciais salvas com sucesso.',
          description: 'Você pode testar a conexão agora.',
        })

        setSavingCredentials(false)
        setLoading(false)

        window.location.href = urlBitrix
      } else {
        setLoading(true)
        setSavingCredentials(true)

        const response = await fetch(
          `${apiUrl.url}/rpa/scheduler/rpa-config-credential/${rpaId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
            body: JSON.stringify(data),
          },
        )

        if (!response.ok) {
          toast({
            type: 'error',
            text: 'Erro ao salvar credenciais.',
            description: 'Tente novamente em instantes.',
          })
          setLoading(false)
          setSavingCredentials(false)
          throw new Error('Failed to update credentials')
        }

        toast({
          type: 'success',
          text: 'Credenciais salvas com sucesso.',
          description: 'Você pode testar a conexão agora.',
        })
        setLoading(false)
        setSavingCredentials(false)
        window.location.reload()
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <>
      <If condition={showLoading}>
        <ModalConnectivitySimples
          id="modal-saving-credentials"
          title={
            showSavingCredentials === true
              ? 'Salvando novas credenciais'
              : 'Teste de Conectividade'
          }
          description={
            showSavingCredentials === true
              ? 'Estamos salvando se suas credenciais, isso pode levar alguns segundos.'
              : 'Estamos verificando se suas credenciais estão em sincronia, isso pode levar alguns segundos.'
          }
          isOpen={showLoading}
          onClose={() => setSavingCredentials(false)}
        />
      </If>
      <If
        condition={
          (configSelected?.type === ENUM_TYPES_SCHEDULER_CONFIG.BITRIX24 ||
            configSelected?.type === ENUM_TYPES_SCHEDULER_CONFIG.OMIE) &&
          !showLoading
        }>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          maxWidth={900}
          showCloseButton={false}
          defaultPaddingContent={'64px 40px'}>
          <S.Container>
            <div className="Container--Header">
              <S.Title>
                {typeEmail === 'AUTOMATIC'
                  ? configSelected?.titleModal2
                  : configSelected?.titleModal}
              </S.Title>
              <S.Description>
                {typeEmail === 'AUTOMATIC'
                  ? configSelected?.descriptionModal2
                  : configSelected?.descriptionModal}
              </S.Description>
            </div>

            <S.WrapperInputs>
              {configSelected?.fields.map((item) => (
                <SContainerCollapse.WrapperInput key={item.name}>
                  <SContainerCollapse.TextLg className="labelInput">
                    {item.label}
                  </SContainerCollapse.TextLg>
                  <TextField
                    showIconPassword={item.showIconPassword}
                    type={item.typeInput}
                    defaultValue={(data?.data as any)?.[item.name]}
                    onChange={(e) =>
                      handleInputChange(item.name, e.target.value)
                    }
                  />
                </SContainerCollapse.WrapperInput>
              ))}
            </S.WrapperInputs>

            <S.WrapperButtons>
              <ButtonPortico variant="outlined" onClick={onClose}>
                Cancelar
              </ButtonPortico>
              <ButtonPortico onClick={handleSave}>
                {' '}
                Salvar alterações
              </ButtonPortico>
            </S.WrapperButtons>
          </S.Container>
        </Modal>
      </If>
      <If
        condition={
          configSelected?.type !== ENUM_TYPES_SCHEDULER_CONFIG.BITRIX24 &&
          configSelected?.type !== ENUM_TYPES_SCHEDULER_CONFIG.OMIE
        }>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          maxWidth={900}
          showCloseButton={false}
          defaultPaddingContent={'64px 40px'}>
          <S.Container>
            <div className="Container--Header">
              <S.Title>
                {typeEmail === 'AUTOMATIC'
                  ? configSelected?.titleModal2
                  : configSelected?.titleModal}
              </S.Title>
              <S.Description>
                {typeEmail === 'AUTOMATIC'
                  ? configSelected?.descriptionModal2
                  : configSelected?.descriptionModal}
              </S.Description>
            </div>

            <S.WrapperInputs>
              <If condition={configSelected?.type !== 'EMAIL'}>
                {configSelected?.fields.map((item) => (
                  <SContainerCollapse.WrapperInput key={item.name}>
                    <SContainerCollapse.TextLg className="labelInput">
                      {item.label}
                    </SContainerCollapse.TextLg>
                    <TextField
                      showIconPassword={item.showIconPassword}
                      type={item.typeInput}
                      defaultValue={(data?.data as any)?.[item.name]}
                    />
                  </SContainerCollapse.WrapperInput>
                ))}
              </If>

              <If
                condition={
                  configSelected?.type === 'EMAIL' && typeEmail === 'CONNECTION'
                }>
                {configSelected?.fields
                  ?.filter((item) => item.typeEmail === EMAIL_TYPE.CONNECTION)
                  .map((item) => (
                    <SContainerCollapse.WrapperInput key={item.name}>
                      <SContainerCollapse.TextLg className="labelInput">
                        {item.label}
                      </SContainerCollapse.TextLg>
                      <TextField
                        showIconPassword={false}
                        type={item.typeInput}
                        value={(dataEmail as any)?.[item.name]}
                        disabled
                      />
                    </SContainerCollapse.WrapperInput>
                  ))}
              </If>

              <If
                condition={
                  configSelected?.type === 'EMAIL' && typeEmail === 'AUTOMATIC'
                }>
                {configSelected?.fields
                  ?.filter((item) => item.typeEmail === EMAIL_TYPE.AUTOMATIC)
                  .map((item) => (
                    <SContainerCollapse.WrapperInput key={item.name}>
                      <SContainerCollapse.TextLg className="labelInput">
                        {item.label}
                      </SContainerCollapse.TextLg>
                      <TextField
                        type={item.typeInput}
                        showIconPassword={false}
                        value={dataEmail?.[item.name]}
                        disabled
                        {...(!!item?.multiline && {
                          multiline: item?.multiline as any,
                        })}
                      />
                    </SContainerCollapse.WrapperInput>
                  ))}
              </If>
            </S.WrapperInputs>

            <S.WrapperButtons>
              <ButtonPortico variant="outlined" onClick={onClose}>
                Cancelar
              </ButtonPortico>
              <ButtonPortico disabled>Salvar alterações</ButtonPortico>
            </S.WrapperButtons>
          </S.Container>
        </Modal>
      </If>
    </>
  )
}
