/* eslint-disable @typescript-eslint/no-explicit-any */
import {QueryKeys} from 'infra'
import {QueryKey, UseQueryOptions, useQuery} from 'react-query'

import {emailService} from '../emailServices'
import {IResponseEmailConfig} from '../emailTypes'

interface IUseEmailConfig
  extends UseQueryOptions<unknown, unknown, unknown, QueryKey> {
  emailId: string
}

export function useGetEmailConfig({
  emailId,
  ...useQueryOptions
}: IUseEmailConfig) {
  return useQuery<IResponseEmailConfig>({
    queryKey: [QueryKeys.GetEmailConfig, {emailId}],
    queryFn: () => {
      return emailService.getEmailConfigs({emailId})
    },
    ...(useQueryOptions as any),
  })
}
