/* eslint-disable @typescript-eslint/no-explicit-any */
import {QueryKeys} from 'infra'
import {QueryKey, UseQueryOptions, useQuery} from 'react-query'

import {emailService} from '../emailServices'
import {IResponseEmailDetail} from '../emailTypes'

interface IUseEmailDetails
  extends UseQueryOptions<unknown, unknown, unknown, QueryKey> {
  emailId: string
}

export function useGetEmailDetails({
  emailId,
  ...useQueryOptions
}: IUseEmailDetails) {
  return useQuery<IResponseEmailDetail>({
    queryKey: [QueryKeys.GetEmailDetails, {emailId}],
    queryFn: async () => {
      return emailService.getEmailDetails({emailId})
    },
    ...(useQueryOptions as any),
  })
}
