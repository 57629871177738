import {COLORS} from '@intraversa-lab/styleguide'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const WrapperTestingConnection = styled.div``

export const Title = styled.p`
  margin: 0px;
  padding: 0px;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.shade[10]};
  text-align: center;

  margin: 32px 0 12px;
`

export const Description = styled.p`
  margin: 0px;
  padding: 0px;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: ${COLORS.neutral[90]};
  text-align: center;

  max-width: 300px;
`

export const LabelCount = styled.p`
  margin: 0px;
  padding: 0px;

  margin-top: 12px;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.neutral[60]};
  text-align: center;
`
