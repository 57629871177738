import styled from 'styled-components'

export const Container = styled.div``

export const Title = styled.h1`
  font-family: Merriweather;
  font-size: 36px;
  font-weight: 400;
  color: #161f28;
  padding: 0px;
  margin: 0px;
`

export const Description = styled.p`
  font-size: 16px;
  font-family: Roboto;
  color: #75808a;
  padding: 0px;
  margin: 0px;
  margin-top: 16px;
`

export const WrapperInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  max-height: 280px;
  overflow: auto;
  margin-top: 32px;
`

export const WrapperButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  margin-top: 32px;
`
