import {LocalStorage} from 'infra'

export const getUser = () => {
  const userLoggedLocalStorage = window.localStorage.getItem(
    LocalStorage.USER_LOGGED,
  )
  if (userLoggedLocalStorage) {
    const parseUser = JSON.parse(userLoggedLocalStorage)
    return parseUser
  }
  return {}
}
