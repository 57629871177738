/* eslint-disable @typescript-eslint/no-explicit-any */
import {toast} from '@intraversa-lab/styleguide'
import {MutationOptions} from 'infra'
import {useMutation} from 'react-query'

import {rpaService} from '../rpaService'

interface Variables {
  companyId: string
  userId: string
}

export function useStopRPA(options?: MutationOptions<any>) {
  const userLoggedLocalStorage = window.localStorage.getItem(
    '@PORTICO:USER_LOGGED',
  )
  const parseUser = JSON.parse(userLoggedLocalStorage)

  const mutation = useMutation<any, Error, Variables>({
    mutationFn: (variables: Variables) =>
      rpaService.postStopRPA({
        userId: parseUser?.id,
        companyId: variables.companyId,
      }),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (authCredentials) => {
      toast({
        type: 'success',
        text: 'Integração pausada',
        description: 'A integração RPA Cadastro foi pausada com sucesso.',
      })
      if (options?.onSuccess) {
        options.onSuccess(authCredentials)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    stopRPA: (variables: Variables) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
