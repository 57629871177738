import React from 'react'

import styled from 'styled-components'

export type BreadcrumbItem = {
  name: string
  value: string
}

export type BreadcrumbsProps = {
  items: BreadcrumbItem[]
  active: string
  onChange: (value: string) => void
}

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    style={{marginRight: '8px', marginLeft: '8px'}} // Ajuste o espaçamento conforme necessário
  >
    <mask
      id="mask0_2962_13604"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2962_13604)">
      <path
        d="M11.043 9.97933L6.91797 5.85433L7.8138 4.9585L12.8346 9.97933L7.8138 15.0002L6.91797 14.1043L11.043 9.97933Z"
        fill="#75808A"
      />
    </g>
  </svg>
)

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items,
  active,
  onChange,
}) => {
  return (
    <BreadcrumbsContainer>
      {items.map((item, index) => (
        <div className="BreadcrumbsContainer__Item" key={item.value}>
          {index > 0 && <Icon />}
          <BreadcrumbsItem
            active={item.value === active}
            onClick={() => onChange(item.value)}>
            {item.name}
          </BreadcrumbsItem>
        </div>
      ))}
    </BreadcrumbsContainer>
  )
}

const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .BreadcrumbsContainer__Item {
    display: flex;
    align-items: center;
  }
`

const BreadcrumbsItem = styled.p<{active: boolean}>`
  padding: 0;
  margin: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: ${({active}) => (active ? '#75808A' : '#161f28')};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
