/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import {Column} from 'types/SchedulerConfig'

export const formatColumns: Record<Column['format'], (value: any) => any> = {
  date: (value) =>
    !!value && value !== null
      ? moment(value).format('DD/MM/YYYY HH:mm:ss')
      : '-',
  boolean: (value) => (value ? 'Sim' : 'Não'),
  checkEmpty: (value) => (!!value && value !== null ? !!value : '-'),
}
