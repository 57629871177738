import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {rpaRunService} from '../rpaRunService'
import {IResponseNextExecution} from '../rpaRunTypes'

export function useGetNextExecution() {
  return useQuery<IResponseNextExecution>({
    queryKey: [QueryKeys.GetNextExecution],
    queryFn: async () => {
      return rpaRunService.getNextExecution()
    },
    staleTime: 1000 * 30,
  })
}
