/* eslint-disable @typescript-eslint/no-explicit-any */
import {toast} from '@intraversa-lab/styleguide'
import {IMutationOptions} from 'infra'
import {useMutation} from 'react-query'

import {rpaRunService} from '../rpaRunService'

export function usePostNow(options?: IMutationOptions) {
  return useMutation<any, Error, any>({
    mutationFn: async () => {
      return await rpaRunService.postRunNow()
    },
    retry: false,
    onError: (error, arg2, arg3) => {
      if (options?.onError) {
        options.onError(error.message, arg2, arg3)
      }
    },
    onSuccess: (data, arg2, ar3) => {
      toast({
        type: 'success',
        text: 'Execução manual realizada',
        description: 'A execução manual foi realizada com sucesso.',
      })
      if (options?.onSuccess) {
        options.onSuccess(data, arg2, ar3)
      }
    },
  })
}
