/* eslint-disable @typescript-eslint/no-explicit-any */
import {IMutationOptions} from 'infra'
import {useMutation} from 'react-query'

import {connectionServices} from '../connectionService'
import {IPayloadTestConnection} from '../connectionTypes'

export function usePostTestConnection(options?: IMutationOptions) {
  const {mutate, isLoading, isSuccess, isError} = useMutation<any, Error, any>({
    mutationFn: async (params: IPayloadTestConnection) => {
      return await connectionServices.postTestConnection(params)
    },
    retry: false,
    onError: (error, arg2, arg3) => {
      if (options?.onError) {
        options.onError(error.message, arg2, arg3)
      }
    },
    onSuccess: (data, arg2, ar3) => {
      if (options?.onSuccess) {
        options.onSuccess(data, arg2, ar3)
      }
    },
  })

  return {
    onTestConnection: (params: IPayloadTestConnection) => mutate(params),
    isLoading,
    isSuccess,
    isError,
  }
}
