/* eslint-disable @typescript-eslint/no-explicit-any */
import {connectionApi} from './connectionApi'
import {IPayloadTestConnection} from './connectionTypes'

const postTestConnection = async (
  params: IPayloadTestConnection,
): Promise<any> => {
  try {
    const {data} = await connectionApi.postTestConnection(params)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export const connectionServices = {
  postTestConnection,
}
