import { styled } from "styled-components";

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eff2f4;
  padding-bottom: 32px;
  margin-bottom: 32px;

  .wrapper-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;

export const Wrapper = styled.div`
  border: 1px solid #dfe4e9;
  border-radius: 4px;
  background: #ffffff;
`;

export const CollapseContainer = styled.div`
  margin: 10px 0;
`;

export const TextLg = styled.p`
  margin: 0;
  padding: 0;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const TextDescription = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #75808a;
`;

export const WrapperInput = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 200px 1fr;

  > .labelInput {
    min-width: max-content;
    margin-top: 18px;
    color: #161f28 !important;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .middle-button {
    margin-left: 16px;
  }
`;

export const CollapseHeader = styled.div`
  cursor: pointer;
  padding: 10px;

  max-height: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 24px 0px;
  margin-bottom: 24px;
`;

export const CollapseContent = styled.div`
  padding: 0px 24px 32px;
  border-top: none;

  ${WrapperInput} + ${WrapperInput} {
    margin-top: 16px;
  }
`;

export const IconButton = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 10px;

  width: 44px;
  height: 44px;

  border: 1px solid #bec9d2;
`;

export const TextFile = styled.p`
  margin: 0;
  padding: 0;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.001em;
  text-align: left;
  color: #c8d0d9;
`;

export const Container = styled.div``;

export const Content = styled.div`
  h1 {
    margin: 0;
  }

  p {
    margin-bottom: 0;
    color: #75808a;
    font-weight: 500;
  }
  @media (max-width: 922px) {
    margin: 0;
  }
`;

export const WrapperSerach = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  position: relative;
  padding: 32px;
  @media (max-width: 650px) {
    gap: 24px;
    padding: 16px;
  }

  button {
    text-transform: capitalize;
    font-size: 16px;
  }

  .newProfile {
    color: #f9f9f9;
    padding: 12px 24px 12px 16px;
    height: 44px;
  }

  .newProfile:active {
    background-color: #59636b;
    color: #c8d0d9;
    border: none;
  }

  .filters {
    display: flex;
    flex: 1;
    gap: 32px;
    width: 100%;

    .inputSearchMobile {
      width: 100%;
    }

    @media (max-width: 650px) {
      gap: 0;
    }
  }

  @media (max-width: 922px) {
    flex-direction: column;
    align-items: normal;
    .filters {
      order: 2;
    }
  }

  .contentSearch {
    flex: 1;
  }

  .content-button {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-items: flex-end;
  }
`;

export const ContentListUser = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 32px;

  @media (max-width: 922px) {
    padding: 0px 16px 0px 16px;
  }
`;

export const CardUser = styled.div`
  height: 92px;
  width: 100%;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid #dfe4e9;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const CardUserMobile = styled.div`
  display: none;
  background-color: #fff;
  padding: 24px;
  width: 100%;

  p {
    margin: 0;
  }

  .name {
    color: #161f28;
    font-size: 16px;
    font-weight: 500;
  }

  .email {
    display: flex;
    gap: 5px;
    color: #3c454d;
    font-size: 14px;
  }

  .contentBox {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    .item {
      > p {
        font-size: 12px;
        font-weight: 500;
        color: #75808a;
      }

      > span {
        font-size: 14px;
        font-weight: 400;
        color: #3c454d;
      }
    }
  }

  .permission {
    margin-top: 12px;
    margin-bottom: 24px;

    > p {
      font-size: 12px;
      color: #75808a;
      font-weight: 500;
    }

    > span {
      font-weight: 400;
      font-size: 14px;
      color: #3c454d;
    }
  }

  .content-btn {
    width: 100%;

    button {
      width: 100% !important;
      padding: 12px 16px 12px 24px;
      text-transform: math-auto !important;
      font-size: 16px;
    }
  }

  @media (max-width: 900px) {
    display: block;
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 44px;

  .first {
    font-weight: 500;
    font-size: 16px;
    color: #161f28;
    margin-bottom: 0;
  }

  p {
    margin: 0;
    font-size: 12px;
    color: #75808a;
    margin-bottom: 4px;
  }

  .email {
    display: flex;
    gap: 8px;
  }

  span {
    color: #3c454d;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const ContenetBtn = styled.div`
  button {
    height: 44px;
    width: 179px;
    border: 1px solid #bec9d2 !important;
    padding: 12px 16px 12px 24px;
    font-size: 16px;
    text-transform: math-auto !important;

    .css-1gnd1fd-MuiButton-endIcon > *:nth-of-type(1) {
      font-size: 18px;
    }
  }

  button:hover {
    background-color: "#DFE4E9 !important";
    border: "1px solid #C8D0D9 !important";
    color: "#000";
  }

  button:active {
    background-color: #bec9d2 !important;
    border: 1px solid #aebcc7 !important;
    color: #3c454d;
  }
`;

export const ContentDescription = styled.div`
  padding: 32px 32px;
  border-bottom: 1px solid #dfe4e9;

  .text-error {
    color: #ff4b38;
    font-size: 14px;
    margin-top: 4px;
    margin-left: 14px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;

    @media (max-width: 850px) {
      gap: 16px;
    }

    .info {
      max-width: 320px;
      width: 100%;
      display: flex;
      flex-direction: column;

      span:first-child {
        color: #161f28;
        font-weight: 500;
        font-size: 1rem;
      }

      span:nth-child(2) {
        font-family: Roboto;
        color: #75808a;
        font-weight: 400;
        color: #75808a;
      }
    }

    @media (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;

      margin-top: 32px;
      margin-bottom: 24px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .content-button {
    display: flex;
    gap: 2rem;

    button {
      /* flex: 1; */
    }
  }

  @media (max-width: 922px) {
    .content-button {
      gap: 16px;
      display: flex;
      flex-direction: column;
      align-items: normal;
      width: 100%;
    }
  }

  @media (max-width: 850px) {
    padding: 0px 16px;
  }

  button {
    text-transform: none !important;
  }
`;

export const ContentBtn = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  text-transform: math-auto !important;
`;

export const CreateProfileModal = styled.div`
  .header-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    .icon-warning {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: #ffe238;
    }

    h1 {
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  p {
    margin-top: 12px;
  }
`;

export const ResetModal = styled.div`
  h1 {
    font-size: 36px;
    color: #161f28;
  }

  p {
    margin-top: 0;
    font-size: 16px;
    color: #75808a;
    font-weight: 400;
  }

  label {
    margin-top: 8px !important;
  }

  label {
    margin-top: 8px !important;
  }

  .MuiFormLabel-root {
    display: none;
  }

  .Mui-error {
    font-size: 12px;
    margin-top: 8px;
  }

  .MuiFormLabel-root {
    display: none;
  }

  .Mui-error {
    margin-top: 0;
  }

  .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input:invalid {
    padding: 0 0 0 24px;
  }

  @media (max-width: 850px) {
    gap: 8px;
    .MuiFormLabel-root {
      display: block;
      padding: 0 16px;
    }
  }
`;

export const footerModal = styled.div`
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0px 30px 32px 0px;
  }

  button {
    line-height: 20px;
  }
`;

export const ContentButtonForm = styled.div`
  display: flex;
  padding-top: 32px;
  padding-left: 384px;
  gap: 32px;

  @media (max-width: 850px) {
    padding: 32px 16px;
    gap: 16px;
  }
`;

export const NotFound = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
